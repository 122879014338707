/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datetime-picker-inline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/datepicker";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "../../../../../../node_modules/ngx-bootstrap/timepicker/ngx-bootstrap-timepicker.ngfactory";
import * as i5 from "ngx-bootstrap/timepicker";
import * as i6 from "@angular/forms";
import * as i7 from "./datetime-picker-inline.component";
var styles_DatetimePickerInlineComponent = [i0.styles];
var RenderType_DatetimePickerInlineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatetimePickerInlineComponent, data: {} });
export { RenderType_DatetimePickerInlineComponent as RenderType_DatetimePickerInlineComponent };
export function View_DatetimePickerInlineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "bs-datepicker-inline", [], null, [[null, "bsValueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("bsValueChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 737280, null, 0, i2.BsDatepickerInlineDirective, [i2.BsDatepickerInlineConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.ComponentLoaderFactory], { bsValue: [0, "bsValue"], isDisabled: [1, "isDisabled"], minDate: [2, "minDate"], maxDate: [3, "maxDate"], datesDisabled: [4, "datesDisabled"] }, { bsValueChange: "bsValueChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "timepicker", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TimepickerComponent_0, i4.RenderType_TimepickerComponent)), i1.ɵprd(512, null, i5.TimepickerStore, i5.TimepickerStore, []), i1.ɵdid(5, 704512, null, 0, i5.TimepickerComponent, [i5.TimepickerConfig, i1.ChangeDetectorRef, i5.TimepickerStore, i5.TimepickerActions], { hourStep: [0, "hourStep"], minuteStep: [1, "minuteStep"], secondsStep: [2, "secondsStep"], readonlyInput: [3, "readonlyInput"], mousewheel: [4, "mousewheel"], arrowkeys: [5, "arrowkeys"], showSpinners: [6, "showSpinners"], showMeridian: [7, "showMeridian"], showMinutes: [8, "showMinutes"], showSeconds: [9, "showSeconds"], meridians: [10, "meridians"], hoursPlaceholder: [11, "hoursPlaceholder"], minutesPlaceholder: [12, "minutesPlaceholder"], secondsPlaceholder: [13, "secondsPlaceholder"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TimepickerComponent]), i1.ɵdid(7, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(9, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "buttons clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "btn-group float-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.now($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "btn-group float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.isDisabled; var currVal_2 = _co.minDate; var currVal_3 = _co.maxDate; var currVal_4 = _co.datesDisabled; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_12 = _co.hourStep; var currVal_13 = _co.minuteStep; var currVal_14 = _co.secondsStep; var currVal_15 = _co.readonlyInput; var currVal_16 = _co.mousewheel; var currVal_17 = _co.arrowkeys; var currVal_18 = _co.showSpinners; var currVal_19 = _co.showMeridian; var currVal_20 = _co.showMinutes; var currVal_21 = _co.showSeconds; var currVal_22 = _co.meridians; var currVal_23 = _co.hoursPlaceholder; var currVal_24 = _co.minutesPlaceholder; var currVal_25 = _co.secondsPlaceholder; _ck(_v, 5, 1, [currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25]); var currVal_26 = _co.value; _ck(_v, 7, 0, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 9).ngClassValid; var currVal_10 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_27 = _co.nowLabel; _ck(_v, 13, 0, currVal_27); var currVal_28 = _co.saveLabel; _ck(_v, 16, 0, currVal_28); }); }
export function View_DatetimePickerInlineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-datetime-picker-inline", [], null, null, null, View_DatetimePickerInlineComponent_0, RenderType_DatetimePickerInlineComponent)), i1.ɵdid(1, 114688, null, 0, i7.DatetimePickerInlineComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DatetimePickerInlineComponentNgFactory = i1.ɵccf("app-datetime-picker-inline", i7.DatetimePickerInlineComponent, View_DatetimePickerInlineComponent_Host_0, { datesDisabled: "datesDisabled", daysDisabled: "daysDisabled", isDisabled: "isDisabled", maxDate: "maxDate", minDate: "minDate", dateCustomClasses: "dateCustomClasses", value: "value", arrowkeys: "arrowkeys", hoursPlaceholder: "hoursPlaceholder", hourStep: "hourStep", meridians: "meridians", minutesPlaceholder: "minutesPlaceholder", minuteStep: "minuteStep", mousewheel: "mousewheel", readonlyInput: "readonlyInput", secondsPlaceholder: "secondsPlaceholder", secondsStep: "secondsStep", showMeridian: "showMeridian", showMinutes: "showMinutes", showSeconds: "showSeconds", showSpinners: "showSpinners", nowLabel: "nowLabel", saveLabel: "saveLabel" }, { valueChange: "valueChange" }, []);
export { DatetimePickerInlineComponentNgFactory as DatetimePickerInlineComponentNgFactory };
