import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { isNgTemplate } from '@angular/compiler';
import { ObjectHydratorService } from '@app/modules/shared/services/object-hydrator.service';
import { LogEntry } from '../user.service';
import { isArray } from 'util';

class Model {
	id?: number;
}

type Filters = {
	page?: number,
	pageSize?: number,
	sortColumn?: string;
	sortDirection?: string;
	filter?: string
}

//type Filters = { [key: string]: string | number | boolean };

function filtersToStr(filters: Filters) {
	//console.log('filters : ', filters)
	let str = [];

	if (filters.page || filters.page === 0) {
		str.push('page='+filters.page);
	}
	if (filters.pageSize) {
		str.push('pageSize='+filters.pageSize);
	}
	if (filters.sortColumn) {
		let direction = filters.sortDirection || 'asc';
		str.push('sort='+filters.sortColumn+':'+direction);
	}
	if (filters.filter) {
		let filter = JSON.parse(filters.filter);
		let filterStr = Array.from(Object.entries(filter))
			.map(item => item[0]+':'+item[1])
			.join(',');
		str.push('filter='+filterStr)
	}

	/*const str1 = Array
		.from(Object.entries(filters))
		.map(filter => {
			if (isObject(filter[1])) {
				filter[1] = Array.from(Object.entries(filter[1])).map(value => value[0]+':'+value[1]).join(',');
			}
			return filter;
		})
		.map(filter => filter[0]+'='+filter[1])
		.join('&');*/
	return str.length ? '?' + str.join('&') : '';
}

export class Pagination<T> {
	total: number;
	page_size: number;
	page: number;
	results: Array<T>;
}

export type MethodType = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH' ;

/**
 * Simple interface to communicate with the backend
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private backendBaseUrl;

	constructor(
		private http: HttpClient,
		private appConfig: AppConfigService,
		private hydrator: ObjectHydratorService) {
		this.backendBaseUrl = appConfig.get('backendBaseUrl');
	}

	/**
	 * Gets a list of an entity type
	 * @param c the type of the entity
	 * @param url the url to fetch the entities
	 * @returns a promise with a list of object
	 */
	list<T>(c: new () => T, url: string, queryParams: Object = null): Promise<T[]> {
		const options: any = {};
		if (queryParams) {
			options.params = queryParams;
		}
		return this.http.get<T[]>(`${this.backendBaseUrl}${url}`, options)
			.pipe(map((data: any) => {
				const items = [];
				for (const dataItem of data) {
					const item = this.hydrator.hydrate(c, dataItem);
					//const item = new c();
					//Object.assign(item, dataItem);
					items.push(item);
				}
				return items;
			}))
			.toPromise();
	}

	/**
	 * Gets an entity from the backend
	 * @param c th eclass of the entity to get
	 * @param url the url of the backend
	 * @param the id of the entity to fetch
	 * @returns A promise of the entity
	 */
	get<T>(c: new () => T, url: string, id: number | string): Promise<T> {

		return this.http.get<T>(`${this.backendBaseUrl}${url}`)
			// Convert result to the entity
			.pipe(map((data) => {
				const item = this.hydrator.hydrate(c, data);
				return item;
				/*const item = new c();
				Object.assign(item, data);
				return item;*/
			}))
			.toPromise<T>();
	}

	/**
	 * Deletes an entity from the backend
	 * @param c the class of the entity
	 * @param url the url of the backend
	 * @param object the object to delete
	 * @returns a promise of the entity
	 */
	delete<T>(c: new () => T, url: string, object: T) {
		if (!(object as any).id) {
			throw new Error('Cant\'t delete an entity without id');
		}
		return this.http.delete<T>(`${this.backendBaseUrl}${url}`)
			.pipe(map(data => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise<T>();
	}

	/**
	 * Creates a new entity on the backend.
	 * @param entity the user to create
	 * @returns a Promise with the new user (with an id)
	 * @throws {Error} if the user already has an id.
	 */
	create<T extends Model>(c: new () => T, url: string, object: T): Promise<T> {
		if (object.id) {
			throw new Error('Can\'t create an entity with an id');
		}
		return this.http.post<T>(`${this.backendBaseUrl}${url}`, object)
			.pipe(map((data) => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise<T>();
	}

	/**
	 * Updates an entity on the backend.
	 * @param c the class name of the entity
	 * @param url the url of the api
	 * @param entity the entity to send to the api
	 * @returns a Promise with the updated entity (with an id)
	 * @throws {Error} if the entity already has an id.
	 */
	update<T extends Model>(c: new () => T, url: string, object: T): Promise<T> {
		if (!object.id) {
			throw new Error('Can\'t update an entity without an id');
		}
		return this.http.put<T>(`${this.backendBaseUrl}${url}`, object)
			.pipe(map((data) => {
				const item = new c();
				Object.assign(item, data);
				return item;
			}))
			.toPromise<T>();
	}

	/**
	 * Updates multiples entities on the backend.
	 * @param c the class name of the entity
	 * @param url the url of the api
	 * @param entity the entity to send to the api
	 * @returns a Promise with the updated entity (with an id)
	 * @throws {Error} if the entity already has an id.
	 */
	updateAll<T extends Model>(c: new () => T, url: string, objects: T[]): Promise<T[]> {
		for(let object of objects) {
			if (!object.id) {
				throw new Error('Can\'t update an entity without an id');
			}
		}

		return this.http.put<T[]>(`${this.backendBaseUrl}${url}`, objects)
			.pipe(map((data) => {
				return data.map(object => {
					const item = new c();
					Object.assign(item, object);
					return item;
				});
			}))
			.toPromise<T[]>();
	}

	/**
	 * Get the history of an entity
	 * @param url the url of the backend
	 * @returns a promise of the log entries
	 */
	history<T extends LogEntry>(c: new () => T, url: string): Promise<T[]> {
		return this.http.get<T[]>(`${this.backendBaseUrl}${url}`)
			.pipe(map((data) => {
				const entries = [];
				for (const entryData of data) {
					const entry = this.hydrator.hydrate(c, entryData);
					entries.push(entry);
				}
				return entries;
			}))
			.toPromise<T[]>();
	}

	/**
	 * Call a custom api
	 * @param c the class name of the entity
	 * @param method the method. Possible values : 'PUT', 'POST', 'PATCH', 'GET' and 'DELETE
	 * @param url the url of the api
	 * @param object an object to send to the api (only with 'PUT', 'POST' and 'PATCH' methods)
	 * @returns a promise with an object or a list of objects
	 */
	custom<T>(c: (new () => T | null), method: MethodType, url: string, object?: T): Promise<T | T[]> {
		let options: any = {};
		if ((method === 'PUT' || method === 'POST' || method === 'PATCH')
			&& !object
		) {
			throw new Error("should set an object with put, post or path methods");
		}

		if (object) {
			options.body = object;
		}

		return this.http.request<T | T[]>(method, `${this.backendBaseUrl}${url}`, options)
			.pipe(map((res: any) => {
				const data = res;
				if (isArray(data)) {
					const items = [];
					for (const dataItem of data) {
						const item = this.hydrator.hydrate(c, dataItem);
						items.push(item);
					}
					return items;
				} else {
					return this.hydrator.hydrate(c, data);
				}
			}))
			.toPromise<T | T[]>();
	}

	/**
	 * Gets a list of an entity type
	 * @param c the type of the entity
	 * @param url the url to fetch the entities
	 * @param filters the optional filters
	 * @returns a promise with a list of object
	 */
	listPaginate<T>(c: new() => T, url: string, filters: Filters = {}): Promise<Pagination<T>> {
		return this.http.get<Pagination<T>>(`${this.backendBaseUrl}${url}${filtersToStr(filters)}`)
			.pipe(map(data  => {
				const pagination = new Pagination<T>();
				pagination.total = data.total;
				pagination.page = data.page;
				pagination.page_size = data.page_size;
				pagination.results = [];
				for(const dataItem of data.results) {
					const item = this.hydrator.hydrate(c, dataItem);
					pagination.results.push(item);
				}
				return pagination;
			}))
			.toPromise();
	}
}
