<div class="input-group"
	 *transloco="let t;">
	<input class="form-control"
		   id="global-search"
		   name="global-search"
		   [(ngModel)]="search"
		   typeaheadOptionField="name"
		   [typeahead]="suggestions$"
		   [typeaheadAsync]="true"
		   [typeaheadItemTemplate]="autocompleteItem"
		   placeholder="{{ t('layout.header.search_placeholder') }}"
		   (typeaheadOnSelect)="onSelect($event)"
		   type="text">
	<div class="input-group-append">
		<span class="input-group-text">
			<i class="fa fa-search"></i>
		</span>
	</div>

	<ng-template #autocompleteItem
				 let-model="item"
				 let-index="index"
				 >
		<div class="d-flex flex-row" *ngIf="model._type == 'customer'">
			<div>
				<i class="fa fa-user"></i>
			</div>
			<div>
				<div>{{ model.name }}</div>
				<div class="text-muted">{{ t('util.type.customer') }}</div>
			</div>
		</div>
		<div class="d-flex flex-row" *ngIf="model._type == 'supplier'">
			<div>
				<i class="fa fa-user-graduate"></i>
			</div>
			<div>
				<div>{{ model.name }}</div>
				<div class="text-muted">{{ t('util.type.supplier') }}</div>
			</div>
		</div>
		<div class="d-flex flex-row" *ngIf="model._type == 'project'">
			<div>
				<i class="fa fa-puzzle-piece"></i>
			</div>
			<div>
				<div>{{ model.name }}</div>
				<div class="text-muted">#: {{model.id}}, moraviaId: {{model.moravia_id}}</div>
				<div class="text-muted">{{ t('util.type.project') }}</div>
			</div>
		</div>
		<div class="d-flex flex-row" *ngIf="model._type == 'task'">
			<div>
				<i class="fas fa-tasks"></i>
			</div>
			<div>
				<div>{{ model.name }}</div>
				<div class="text-muted">#: {{model.id}}, moraviaId: {{model.moravia_id}}</div>
				<div class="text-muted">{{ t('util.type.task') }}</div>
			</div>
		</div>
	</ng-template>
</div>