import { Router } from '@angular/router';
import { AuthService } from './api/auth.service';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api/auth.service";
import * as i2 from "@angular/router";
/**
 * Guard that canActivate if the user is authenticated.
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
var AuthGuardService = /** @class */ (function () {
    /**
     * Constructs the Guard
     * @param auth
     * @param router
     */
    function AuthGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    /**
     * Test if the user is authenticated
     * @returns An observable resolving into true if the user is authenticated, false otherwise
     */
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth.getAuthenticatedUser().subscribe(function (user) {
                observer.next(true);
                observer.complete();
            }, function (err) {
                _this.router.navigate(['login']);
                observer.next(false);
                observer.complete();
            });
        });
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
