
/** @ignore */
interface NavAttributes {
	[propName: string]: any;
}
/** @ignore */
interface NavWrapper {
	attributes: NavAttributes;
	element: string;
}
/** @ignore */
interface NavBadge {
	text: string;
	variant: string;
}
/** @ignore */
interface NavLabel {
	class?: string;
	variant: string;
}

/** @ignore */
export interface NavData {
	name?: string;
	url?: string;
	icon?: string;
	badge?: NavBadge;
	title?: boolean;
	children?: NavData[];
	variant?: string;
	attributes?: NavAttributes;
	divider?: boolean;
	class?: string;
	label?: NavLabel;
	wrapper?: NavWrapper;
	translated?: boolean;
	permissions?: string[];
}

/** @ignore */
export const navItems: NavData[] = [
	{
		name: 'layout.menu.customers_menu_item',
		url: '/customers',
		icon: 'fa fa-user-tie',
		permissions: ['permission.customer.list', 'permission.customer.own.list']
	},
	{
		name: 'layout.menu.suppliers_menu_item',
		url: '/suppliers',
		icon: 'fa fa-user-graduate',
		permissions: ['permission.supplier.list']
	},
	{
		name: 'layout.menu.projects_menu_item',
		url: '/projects',
		icon: 'fa fa-puzzle-piece',
		permissions: ['permission.project.list', 'permission.project.own.list']
	},
	{
		name: 'layout.menu.planning_menu_item',
		url: '/projects/planning',
		icon: 'fa fa-tachometer-alt' // icon-speedomerter
	},
	{
		name: 'layout.menu.purchase_orders_menu_item',
		url: '/purchase-orders/planning/suppliers',
		icon: 'fas fa-clipboard-list',
		permissions: ['permission.purchase-order.list', 'permission.purchase-order.own.list']
	},
	{
		name: 'layout.menu.administration_menu_item',
		url: '/administration',
		icon: 'fa fa-cog',
		permissions: ['permission.user.list']
	}
];
