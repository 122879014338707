
<div class="modal-warning" *transloco="let t;">
		<h4 class="modal-title" id="modal-basic-title">
			<i class="fa fa-exclamation-triangle"></i>
			<span>{{ t('warnings.unsaved_form.title') }}</span>
		</h4>
		<div class="modal-body" [innerHTML]="t('warnings.unsaved_form.message')"></div>
		<div class="modal-footer">
			<button type="button" class="btn btn-default cancel" (click)="cancel()">{{ t('warnings.unsaved_form.cancel_button') }}</button>
			<button type="button" class="btn btn-warning continue" (click)="continue()" [ladda]="loading" [disabled]="error">{{ t('warnings.unsaved_form.continue_button') }}</button>
		</div>
	</div>