import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanIconComponent } from './components/boolean-icon/boolean-icon.component';
import { JdcFormSubmitDirective } from './directives/forms/jdc-form-submit.directive';
import { ToastrComponent } from './components/toastr/toastr.component';
import { NgxMaskModule } from 'ngx-mask';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PHONE_INPUT_MASKS } from './components/phone-input/phone-mask-token';
import { masks } from './components/phone-input/phone-masks';
import { LaddaModule } from 'angular2-ladda';
import { IsDirtyComponent } from './components/is-dirty/is-dirty.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { TableSearchComponent } from './components/table-search/table-search.component';
import { UserTagComponent } from './components/user-tag/user-tag.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RouterModule } from '@angular/router';
import { ToSnakeCasePipe } from './pipes/to-snake-case.pipe';
import { InputDatetimeComponent } from './components/input-datetime/input-datetime.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { DatetimePickerInlineComponent } from './components/datetime-picker-inline/datetime-picker-inline.component';
import { DatetimePickerDirective } from './directives/datetime-picker/datetime-picker.directive';
import { InputDatetimePickerDirective } from './directives/input-datetime-picker/input-datetime-picker.directive';
import { MaskDirective } from './directives/mask/mask.directive';
import { NumberMaskDirective } from './directives/number-mask.directive';
import { MailEditorComponent } from './components/mail-editor/mail-editor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { BrowseButtonComponent } from './components/browse-button/browse-button.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { SecurePipe } from './pipes/secure.pipe';
import { FormComponent } from './components/form/form.component';
import { HistoryModalComponent } from './components/history-modal/history-modal.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule,
		LaddaModule,
		BsDropdownModule,
		TranslocoModule,
		PopoverModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		//DatetimePopupModule.forRoot(),
		NgSelectModule,
		CKEditorModule,
		DragToSelectModule.forRoot()
	],
	declarations: [
		BooleanIconComponent,
		JdcFormSubmitDirective,
		ToastrComponent,
		PhoneInputComponent,
		IsDirtyComponent,
		ConfirmModalComponent,
		TableSearchComponent,
		UserTagComponent,
		ToSnakeCasePipe,
		InputDatetimeComponent,
		DatetimePickerInlineComponent,
		DatetimePickerDirective,
		InputDatetimePickerDirective,
		MaskDirective,
		NumberMaskDirective,
		MailEditorComponent,
		DragAndDropDirective,
		FileUploadModalComponent,
		ConfirmComponent,
		BrowseButtonComponent,
		ImageInputComponent,
		SecurePipe,
		FormComponent,
		HistoryModalComponent
	],
	exports: [
		BooleanIconComponent,
		JdcFormSubmitDirective,
		PhoneInputComponent,
		TableSearchComponent,
		UserTagComponent,
		ToSnakeCasePipe,
		InputDatetimeComponent,
		DatetimePickerDirective,
		InputDatetimePickerDirective,
		MaskDirective,
		NumberMaskDirective,
		MailEditorComponent,
		DragAndDropDirective,
		ConfirmComponent,
		BrowseButtonComponent,
		ImageInputComponent,
		SecurePipe,
		FormComponent,
		HistoryModalComponent
	],
	entryComponents: [
		ToastrComponent,
		IsDirtyComponent,
		ConfirmModalComponent,
		FormComponent,
		DatetimePickerInlineComponent,
		HistoryModalComponent
	],
	providers: [
		{ provide: PHONE_INPUT_MASKS, useValue: masks }
	]
})
export class SharedModule {
	constructor() {
	}
}
