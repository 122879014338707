export interface Permission {
    id: number;
    name: string;
}

export class Role {
    constructor(
        id: number = null,
        name: string = null,
        permissions: Permission[] = [],
        children_roles?: Role[]
    ) {
        this.id = id;
        this.name = name;
        this.permissions = permissions;
        if (children_roles) {
            this.children_roles = children_roles;
        }
    }
    id: number;
    name: string;
    children_roles?: Role[] = [];
    permissions: Permission[] = [];
}

export function walkRoles(roles, fn): void {
	for(const role of roles) {
		fn(role);
		if (role.children_roles) {
			walkRoles(role.children_roles, fn);
		}
	}
}