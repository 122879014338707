import * as tslib_1 from "tslib";
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
var ToastrComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ToastrComponent, _super);
    function ToastrComponent(toastrService, toastPackage) {
        var _this = _super.call(this, toastrService, toastPackage) || this;
        _this.toastrService = toastrService;
        _this.toastPackage = toastPackage;
        return _this;
    }
    ToastrComponent.prototype.action = function (event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    };
    return ToastrComponent;
}(Toast));
export { ToastrComponent };
