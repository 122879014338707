import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Service to get data from the current route to set the breadcrumb
 * This is used to override coreui AppBreadcrumbService of CoreUI to add the possibility
 * to translate breadcrumbs using Transloco (and keeping to set the key into route).
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {

	/** The breadcrumb data */
	breadcrumbs: Observable<Array<Object>>;

	/** Whatcher */
	private _breadcrumbs: BehaviorSubject<Array<Object>>;

	/**
	 * Constructs the service
	 * @param router
	 * @param route
	 * @param transloco
	 */
	constructor(private router: Router, private route: ActivatedRoute) {

		this._breadcrumbs = new BehaviorSubject<Object[]>(new Array<Object>());
		this.breadcrumbs = this._breadcrumbs.asObservable();

		// Catches route change events
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
			const breadcrumbs = [];
			let currentRoute = this.route.root,
				url = '';
			let previousTitle = '';
			let data = {};
			do {
				//console.log('currentRoute : ', currentRoute.url.(), currentRoute);
				const childrenRoutes = currentRoute.children;
				currentRoute = null;
				// tslint:disable-next-line:no-shadowed-variable
				childrenRoutes.forEach(route => {
					if (route.outlet === 'primary') {
						const routeSnapshot = route.snapshot;

						url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
						url = url.replace('//', '/');

						if (route.snapshot.data.title !== previousTitle) {
							previousTitle = route.snapshot.data.title;
							//console.log('route data : ', route.snapshot.data);
							Object.assign(data, route.snapshot.data)
							//console.log('data : ', data)
							breadcrumbs.push({
								label: Object.assign({}, data),
								url: url
							});
						}

						currentRoute = route;
					}
				});
			} while (currentRoute);

			this._breadcrumbs.next(Object.assign([], breadcrumbs));

			return breadcrumbs;
		});
	}
}
