import { isObject } from 'util';
import 'reflect-metadata';
import * as i0 from "@angular/core";
export function Relation(type) {
    return function (target, propertyKey) {
        Reflect.defineMetadata('type', type, target, propertyKey);
    };
}
export function DateDef() {
    return function (target, propertyKey) {
        Reflect.defineMetadata('date', Date, target, propertyKey);
    };
}
var ObjectHydratorService = /** @class */ (function () {
    function ObjectHydratorService() {
    }
    ObjectHydratorService.prototype.hydrate = function (type, data) {
        var model = new type();
        for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
            var _b = _a[_i], property = _b[0], value = _b[1];
            if (isObject(data[property])) {
                var propertyType = Reflect.getMetadata('type', model, property);
                if (propertyType) {
                    if (Array.isArray(data[property])) {
                        model[property] = [];
                        for (var _c = 0, _d = data[property]; _c < _d.length; _c++) {
                            var item = _d[_c];
                            model[property].push(this.hydrate(propertyType, item));
                        }
                    }
                    else {
                        model[property] = this.hydrate(propertyType, data[property]);
                    }
                }
                else {
                    model[property] = data[property];
                }
            }
            else {
                var dateType = Reflect.getMetadata('date', model, property);
                if (dateType) {
                    model[property] = new Date(data[property]);
                }
                else {
                    model[property] = data[property];
                }
            }
        }
        return model;
    };
    ObjectHydratorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectHydratorService_Factory() { return new ObjectHydratorService(); }, token: ObjectHydratorService, providedIn: "root" });
    return ObjectHydratorService;
}());
export { ObjectHydratorService };
