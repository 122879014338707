/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "angular2-ladda";
import * as i4 from "@ngneat/transloco";
import * as i5 from "./confirm-modal.component";
import * as i6 from "ngx-bootstrap/modal";
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
function View_ConfirmModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "modal-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-default cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["type", "button"]], [[1, "class", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 737280, null, 0, i3.LaddaDirective, [i1.ElementRef, [2, i3.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.error; _ck(_v, 7, 0, currVal_3); var currVal_6 = _co.loading; var currVal_7 = _co.error; _ck(_v, 12, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClasses; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit(_co.title, _co.data); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit(_co.message, _co.data); _ck(_v, 5, 0, currVal_2); var currVal_4 = _v.context.$implicit(_co.cancelLabel); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.continueClasses; _ck(_v, 11, 0, currVal_5); var currVal_8 = _v.context.$implicit(_co.continueLabel); _ck(_v, 13, 0, currVal_8); }); }
export function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_1)), i1.ɵdid(1, 737280, null, 0, i4.TranslocoDirective, [i4.TranslocoService, [2, i1.TemplateRef], [2, i4.TRANSLOCO_SCOPE], [2, i4.TRANSLOCO_LANG], [2, i4.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 180224, null, 0, i5.ConfirmModalComponent, [i6.BsModalRef, i6.BsModalService], null, null)], null, null); }
var ConfirmModalComponentNgFactory = i1.ɵccf("app-confirm-modal", i5.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
