/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./form.component";
var styles_FormComponent = [];
var RenderType_FormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
export function View_FormComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i0.ɵdid(1, 180224, null, 0, i1.FormComponent, [], null, null)], null, null); }
var FormComponentNgFactory = i0.ɵccf("app-form", i1.FormComponent, View_FormComponent_Host_0, { _errors: "errors" }, {}, []);
export { FormComponentNgFactory as FormComponentNgFactory };
