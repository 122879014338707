<div class="modal-warning" *transloco="let t;">
	<h4 class="modal-title" id="modal-basic-title">
        <i [attr.class]="iconClasses"></i>
        <span>{{ t(title, data) }}</span>
    </h4>
	<div class="modal-body" [innerHTML]="t(message, data)"></div>
	<div class="error alert alert-danger" *ngIf="error">{{ error }}</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-default cancel" (click)="cancel()">{{ t(cancelLabel) }}</button>
		<button type="button" [attr.class]="continueClasses" (click)="continue()" [ladda]="loading" [disabled]="error">{{ t(continueLabel) }}</button>
	</div>
</div>