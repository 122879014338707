export class Supplier {
	id:number;
	name: string;
	email: string;
	customers: Array<any> = [];

	getFullName() {
		return this.name;
	}

	toString() {
		return this.getFullName();
	}

	hasCustomerOfId(id) {
		for(let customer of this.customers) {
			if (customer.id == id) {
				return true;
			}
		}
		return false;
	}
}