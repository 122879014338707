import { Component, OnInit } from '@angular/core';
import { Customer } from '@app/modules/customer/models/customer';
import { Supplier } from '@app/modules/supplier/models/supplier';
import { Project } from '@app/modules/project/models/project';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Task } from '@app/modules/project/models/task';

@Component({
	selector: 'app-history-modal',
	templateUrl: './history-modal.component.html',
	styleUrls: ['./history-modal.component.css']
})
export class HistoryModalComponent implements OnInit {

	constructor(private modal: BsModalRef) { }

	ngOnInit(): void {
	}

	getValueType(value) {
		if (value instanceof Array) {
			return 'array';
		}
		if (typeof value == "boolean") {
			return 'boolean';
		}
		if (typeof value === "string") {
			return 'string';
		}
		if (value instanceof Date) {
			return 'date';
		}
		return 'object';
	}

	getObjectLink(value) {
		if (value instanceof Customer) {
			return ['/customers', value.id];
		}
		if (value instanceof Supplier) {
			return ['/suppliers', value.id]
		}
		if (value instanceof Project) {
			return ['/projects', value.id];
		}
		if (value instanceof Task) {
			if (value.project && value.id) {
				return ['/projects', value.project.id, 'tasks', value.id];
			}
		}
		return;
	}

	close() {
		this.modal.hide();
	}

}
