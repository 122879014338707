import { Injectable } from '@angular/core';
import { isObject } from 'util';
import 'reflect-metadata';

export function Relation(type: any) {
	return function (target: any, propertyKey: string | symbol) {
		Reflect.defineMetadata('type', type, target, propertyKey);
	}
}

export function DateDef() {
	return function (target: any, propertyKey: string | symbol) {
		Reflect.defineMetadata('date', Date, target, propertyKey);
	}
}


@Injectable({
	providedIn: 'root'
})
export class ObjectHydratorService {

	constructor() {
	}

	hydrate(type, data: any) {
		const model = new type();
		for (const [property, value] of Object.entries(data)) {

			if (isObject(data[property])) {
				let propertyType = Reflect.getMetadata('type', model, property);
				if (propertyType) {
					if (Array.isArray(data[property])) {
						model[property] = [];
						for (let item of data[property]) {
							model[property].push(this.hydrate(propertyType, item));
						}
					} else {
						model[property] = this.hydrate(propertyType, data[property]);
					}
				} else {
					model[property] = data[property];
				}
			} else {
				let dateType = Reflect.getMetadata('date', model, property);
				if (dateType) {
					model[property] = new Date(data[property]);
				} else {
					model[property] = data[property];
				}

			}
		}
		return model;
	}
}
