import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';
import { User } from '../../models/user';
import { StorageService } from '../storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app-config.service";
import * as i3 from "../storage.service";
/**
 * Represents a service to log the user in and resetting the password
 * @class AuthService
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
var AuthService = /** @class */ (function () {
    /**
     * Constructs the service
     * @param http
     * @param appConfig
     */
    function AuthService(http, appConfig, storage) {
        this.http = http;
        this.appConfig = appConfig;
        this.storage = storage;
        /**
         * The authtenticated user, null otherwise
         */
        this._authenticatedUser$ = new BehaviorSubject(null);
        this.authenticatedUser$ = this._authenticatedUser$.pipe();
        this.backendBaseUrl = appConfig.get('backendBaseUrl');
    }
    /**
     * Tries to log the user in with given username and password
     * @param username the username
     * @param password the password
     * @returns An observable with the result of the request :
     *   * If the login succeed, a 200 response is sent
     *   * If the login failed, a 401 response is sent
     */
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        var formData = {
            username: username,
            password: password
        };
        return this.http.post(this.backendBaseUrl + '/login', formData)
            .pipe(map(function (data) {
            _this.storage.set('token', data.token);
            var user = new User();
            Object.assign(user, data.user);
            _this._authenticatedUser$.next(user);
            return user;
        }));
    };
    /**
     * Tries to log the user out
     * @returns An observable with the result of the request.
     */
    AuthService.prototype.logout = function () {
        var _this = this;
        this._authenticatedUser$.next(null);
        return this.http.get(this.backendBaseUrl + '/logout')
            .pipe(map(function (data) {
            _this.storage.remove('token');
            var user = new User();
            Object.assign(user, data);
            return user;
        })).pipe(catchError(function (err) {
            return throwError(err);
        }));
    };
    /**
     * Ask to the backend to send an activation code by mail
     * @param email email the email to which the code should be sent
     * @return An observable with the result of the request :
     *   * If the reqsuest succeed, a 200 response
     *   * If there is not account related to the email, a 404 response
     *   * If there is another error, a 500 response
     */
    AuthService.prototype.sendResetPassword = function (email) {
        return this.http.post(this.backendBaseUrl + '/reset-password', {
            email: email
        });
    };
    /**
     * Ask to the server to validate the code and save the new password
     * @param email the email of the account
     * @param code the reset code
     * @param password the new password
     * @returns An observable with the response of the request
     */
    AuthService.prototype.validatePassword = function (email, code, password) {
        return this.http.post(this.backendBaseUrl + '/reset-password-validate', {
            email: email,
            code: code,
            password: password
        });
    };
    /**
     * Indicates if the user is authenticated or not
     * @returns true if the user is authenticated, false otherwise.
     */
    AuthService.prototype.isAuthenticated = function () {
        return this._authenticatedUser$.getValue() != null;
    };
    /**
     * Gets the authenticated user from the backend if any, null otherwise.
     * @throws {HttpErrorResponse} if an error occured
     * @returns the authenticated user
     */
    AuthService.prototype.getAuthenticatedUser = function () {
        var _this = this;
        return this.http.get(this.backendBaseUrl + '/authenticated')
            .pipe(take(1), map(function (data) {
            var user = new User();
            Object.assign(user, data);
            _this._authenticatedUser$.next(user);
            return user;
        }));
        // return this._authenticatedUser$.asObservable();
    };
    AuthService.prototype.getCurrentAuthenticatedUser = function () {
        return this._authenticatedUser$.getValue();
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.StorageService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
