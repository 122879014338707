
import { Relation, DateDef } from '@app/modules/shared/services/object-hydrator.service';
import { Supplier } from '@app/modules/supplier/models/supplier';
import { User } from '@app/models/user';
import { Status } from './status';
import { Customer } from '@app/modules/customer/models/customer';
import { Task } from './task';
import { Division } from '@app/modules/customer/models/division';

export class Project {
	id:number;
	moravia_id: number;
	name: string;
	// Customer
	@Relation(Customer) customer: Customer;
	customer_deadline: string;
	// Translator
	@Relation(Supplier) translator: Supplier;
	translator_deadline: string;
	translation_is_delivered: boolean;
	// Proofreader
	@Relation(Supplier) proofreader: Supplier;
	@DateDef() proofreading_deadline: Date;
	proofreading_is_delivered: boolean;
	// Status
	@Relation(Status) status: Status;

	// Job
	moravia_job_id: string;
	moravia_job_name: string;

	word_count: number;
	wwc: number;

	@Relation(Division) division: Division;

	@Relation(Task) tasks: Task[] = [];

	toString() {
		return this.moravia_id;
	}

	hasManager(user: User) {
		for(let customer of user.customers) {
			if( customer.id == this.customer.id) {
				return true;
			}
		}
		return false;
	}
}