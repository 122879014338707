import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';



@Component({
	selector: 'app-changelog',
	templateUrl: './changelog.component.html',
	styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {

	public changelog: any;

	constructor() {
		this.changelog = require('../../../assets/changelog.json');
	}

	originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
		return 0;
	}


	ngOnInit(): void {
	}

}
