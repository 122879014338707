export class Customer {
	id:number;
	name: string;
	short_name: string;
	email: string;
	sender_email: string;

	toString() {
		return this.name;
	}
}