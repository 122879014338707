import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Service to get data from the current route to set the breadcrumb
 * This is used to override coreui AppBreadcrumbService of CoreUI to add the possibility
 * to translate breadcrumbs using Transloco (and keeping to set the key into route).
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
var BreadcrumbService = /** @class */ (function () {
    /**
     * Constructs the service
     * @param router
     * @param route
     * @param transloco
     */
    function BreadcrumbService(router, route) {
        var _this = this;
        this.router = router;
        this.route = route;
        this._breadcrumbs = new BehaviorSubject(new Array());
        this.breadcrumbs = this._breadcrumbs.asObservable();
        // Catches route change events
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            var breadcrumbs = [];
            var currentRoute = _this.route.root, url = '';
            var previousTitle = '';
            var data = {};
            do {
                //console.log('currentRoute : ', currentRoute.url.(), currentRoute);
                var childrenRoutes = currentRoute.children;
                currentRoute = null;
                // tslint:disable-next-line:no-shadowed-variable
                childrenRoutes.forEach(function (route) {
                    if (route.outlet === 'primary') {
                        var routeSnapshot = route.snapshot;
                        url += '/' + routeSnapshot.url.map(function (segment) { return segment.path; }).join('/');
                        url = url.replace('//', '/');
                        if (route.snapshot.data.title !== previousTitle) {
                            previousTitle = route.snapshot.data.title;
                            //console.log('route data : ', route.snapshot.data);
                            Object.assign(data, route.snapshot.data);
                            //console.log('data : ', data)
                            breadcrumbs.push({
                                label: Object.assign({}, data),
                                url: url
                            });
                        }
                        currentRoute = route;
                    }
                });
            } while (currentRoute);
            _this._breadcrumbs.next(Object.assign([], breadcrumbs));
            return breadcrumbs;
        });
    }
    BreadcrumbService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbService_Factory() { return new BreadcrumbService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: BreadcrumbService, providedIn: "root" });
    return BreadcrumbService;
}());
export { BreadcrumbService };
