export class Division {
	id: number;
	name: string;
	qa_panel: boolean;

	customer: Object;


	toString() {
		return this.name;
	}
}