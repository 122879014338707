import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';

@Component({
	selector: 'app-datetime-picker-inline',
	templateUrl: './datetime-picker-inline.component.html',
	styleUrls: ['./datetime-picker-inline.component.scss']
})
export class DatetimePickerInlineComponent implements OnInit {

	@Input() datesDisabled: Date[] = [];

	@Input() daysDisabled: number[] = [];

	@Input() isDisabled: boolean = false;

	@Input() maxDate: Date = null;

	@Input() minDate: Date = null;

	@Input() dateCustomClasses: DatepickerDateCustomClasses[];

	@Input() value: Date;

	@Output() valueChange: EventEmitter<Date> = new EventEmitter();

	/**
	 * Time
	 */
	@Input() arrowkeys: boolean = true;

	@Input() hoursPlaceholder: string = "HH";

	@Input() hourStep: number = 1;

	@Input() meridians: string[] = [];

	@Input() minutesPlaceholder: string = "MM";

	@Input() minuteStep: number = 1;

	@Input() mousewheel: boolean = false;

	@Input() readonlyInput: boolean = false;

	@Input() secondsPlaceholder: string = "SS";

	@Input() secondsStep: number = 1;

	@Input() showMeridian: boolean = false;

	@Input() showMinutes: boolean = true;

	@Input() showSeconds: boolean = false;

	@Input() showSpinners: boolean = true;

	@Input() nowLabel: string = "Now";

	@Input() saveLabel: string = "Save";

	constructor() { }

	ngOnInit() {}

	now($event?) {
		this.value = new Date();
	}

	save($event) {
		this.valueChange.emit(this.value);
	}

}
