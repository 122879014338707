import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnDestroy {

	title: string;
	message: string;
	cancelLabel: string = 'warnings.cancel_button';
	continueLabel: string = 'warnings.continue_button';
	data: any;

	iconClasses = 'fa fa-exclamation-triangle';
	continueClasses = 'btn btn-warning continue';

	private error?: string = null;
	public loading: boolean = false;
	protected _onContinue = new Subject();
	public onContinue = this._onContinue.pipe(take(1)).toPromise();

	private hideSub: Subscription;

	constructor(private modal: BsModalRef, private modalService: BsModalService) {
		this.hideSub = this.modalService.onHide
			.pipe(untilDestroyed(this))
			.subscribe($event => {
				if ($event == 'backdrop-click' || $event == 'esc' || $event == 'cancel') {
					this._onContinue.error("cancel");
				}
			});
	}

	cancel() {
		this.modalService.setDismissReason("cancel");
		//this.modalService.hide(1);
		//this._onContinue.error("cancel");
		this.modal.hide();
	}

	continue() {
		this.loading = true;
		this._onContinue.next('continue');
		//this.dismiss();
	}

	dismiss(reason: string = "continue") {
		console.log('main continue');
		this.loading = false;
		this.modalService.setDismissReason(reason);
		this.modal.hide();
	}

	ngOnDestroy() {
		console.log('destroy');
		this.hideSub.unsubscribe();
		this._onContinue.complete();
		this._onContinue.unsubscribe();
	}

	setContinueLoading(loading: boolean) {
		this.loading = loading;
	}

	setError(error?: string) {
		this.loading = false;
		this.error = error;
	}

	isLoading() {
		return this.loading;
	}
}
