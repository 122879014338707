/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i2 from "@ngx-loading-bar/core";
import * as i3 from "@angular/router";
import * as i4 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "routeAnimations", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 10, animation: { type: 8, animation: [{ type: 6, styles: { opacity: 100 }, offset: null }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 6, styles: { opacity: 100 }, offset: null }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 100 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 100 }, offset: null }, timings: "{{time}}" }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "{{time}}" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 9, options: null }, options: { optional: true } }, { type: 11, selector: ":leave", animation: { type: 9, options: null }, options: { optional: true } }], options: null }, options: { params: { time: "0.5s" } } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i1.View_LoadingBarComponent_0, i1.RenderType_LoadingBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoadingBarComponent, [i2.LoadingBarService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], [[24, "@routeAnimations", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, [["outlet", 4]], 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.prepareRoute(i0.ɵnov(_v, 4)); _ck(_v, 2, 0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i4.AppComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("body", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
