import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

/**
 * A component used in modal to warn the user that
 * a component is dirty and ask if he wants to continue
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Component({
	selector: 'app-is-dirty',
	templateUrl: './is-dirty.component.html',
	styleUrls: ['./is-dirty.component.scss']
})
export class IsDirtyComponent {

	/**
	 * Constructs the component
	 * @param modal the modal reference
	 * @param modalService the modal service
	 */
	constructor(private modal: BsModalRef, private modalService: BsModalService) {}

	/**
	 * Callback for the "continue" button
	 */
	continue(): void {
		this.modalService.setDismissReason("continue");
		this.modal.hide();
	}

	/**
	 * Callback for the "cancel" button
	 */
	cancel(): void {
		this.modalService.setDismissReason("cancel");
		this.modal.hide();
	}

}
