import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap, finalize } from 'rxjs/operators';
import { AuthService } from '../api/auth.service';

/**
 * Intercepts any HttpRequest and checks if response has a 401 response error.
 * It it the case, it redirect to the login page
 */
@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError((err: any): ObservableInput<any> => {
			if (err.status === 401) {
				this.router.navigate(['login']);
				return throwError(err);
			} else {
				return throwError(err);
			}
		}));
	}
}
