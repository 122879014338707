import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.prototype.set = function (key, value) {
        return localStorage.setItem(key, value);
    };
    StorageService.prototype.has = function (key) {
        return of(localStorage.getItem(key)).pipe(map(function (value) {
            if (value) {
                return true;
            }
            return false;
        })).toPromise();
    };
    StorageService.prototype.get = function (key, defaultValue) {
        if (defaultValue === void 0) { defaultValue = undefined; }
        return of(localStorage.getItem(key)).pipe(map(function (value) {
            if (value) {
                return value;
            }
            return defaultValue;
        })).toPromise();
    };
    StorageService.prototype.remove = function (key) {
        return localStorage.removeItem(key);
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
