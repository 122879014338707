import { Customer } from '@app/modules/customer/models/customer';
import { Role, walkRoles } from '../modules/user/models/role';

/**
 * Represents a user of the application
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
export class User {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	mobile_phone_number: string;
	landline_phone_number: string;
	is_active: boolean;
	deactivated_at: Date;
	user_roles: Array<any>;
	customers: Array<Customer> = [];

	permissions?: any;

	/**
	 * Get the full name of the user
	 * @returns the full name
	 */
	public getFullName(): string {
		return ((this.first_name || '') + ' ' + (this.last_name || '')).trim();
	}

	public getRolesIds(): number[] {
		if (this.user_roles) {
			return this.user_roles.map(r => r.role.id);
		}
		return [];
	}

	public getRoles(): Role[] {
		if (this.user_roles) {
			return this.user_roles.map(r => r.role);
		}
		return [];
	}

	public setRoles(roles: Role[]): void {
		this.user_roles = roles.map(role => {
			return { role: role };
		});
	}

	/**
	 * Checks if the user has a given permission
	 * @param permission
	 */
	public hasPermission(permission: string) {
		return this.hasPermissions([permission]);
	}

	public hasPermissions(permissions: string[]): boolean {
		//console.log('hasPermissios : ', permissions.join(','))
		function _hasPermissions(roles): boolean {
			//console.log('_hasPermissions : ', roles.map(r => r.name).join(','))
			for(let role of roles) {
				//console.log('check role ', role.name);
				if (role.permissions) {
					for(let permission of role.permissions) {
						//console.log('check for ', permission.name);
						if (permissions.indexOf(permission.name) >= 0) {
							// has permission
							//console.log('got it')
							return true;
						}
					}
				}

				if (role.children_roles) {
					//console.log('check children of ', role.name);
					if ( _hasPermissions(role.children_roles)) {
						return true;
					}
				}
			}

			return false;
		}

		if (this.user_roles) {
			return _hasPermissions(this.user_roles.map(r => r.role));
		}
		return false;
	}

	public getAllPermissions(): string[] {
		let permissions = [];
		walkRoles(this.getRoles(), role => {
			permissions = permissions.concat(
				(role.permissions || []).map(permission => permission.name)
			);
		});
		return permissions;
	}
}

