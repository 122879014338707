import * as tslib_1 from "tslib";
import { Supplier } from '@app/modules/supplier/models/supplier';
import { Relation, DateDef } from '@app/modules/shared/services/object-hydrator.service';
import { TaskStatus } from './task-status';
var Task = /** @class */ (function () {
    function Task() {
    }
    Task.prototype.clone = function () {
        var clone = new Task();
        clone.id = this.id;
        clone.moravia_id = this.moravia_id;
        clone.name = this.name;
        clone.project = this.project;
        clone.translator = this.translator;
        clone.translator_deadline = this.translator_deadline;
        clone.translation_status = this.translation_status;
        clone.proofreader = this.proofreader;
        clone.proofreader_deadline = this.proofreader_deadline;
        clone.proofreading_status = this.proofreading_status;
        clone.sourceLanguage = this.sourceLanguage;
        clone.targetLanguage = this.targetLanguage;
        clone.word_count = this.word_count;
        clone.wwc = this.wwc;
        clone.hours = this.hours;
        clone.wwc_updated = this.wwc_updated;
        clone.wwc_is_handmade = this.wwc_is_handmade;
        return clone;
    };
    Task.prototype.toString = function () {
        return this.moravia_id;
    };
    Task.prototype.getMoraviaUrl = function () {
        return 'https://projects.moravia.com/task/' + this.moravia_id + '/attachments';
    };
    Task.prototype.hasManager = function (user) {
        for (var _i = 0, _a = user.customers; _i < _a.length; _i++) {
            var customer = _a[_i];
            if (this.project.customer.id == customer.id) {
                return true;
            }
        }
        return false;
    };
    Task.prototype.isClosedToDate = function () {
        var codes = ['FINISHED', 'RECEIVED'];
        var now = new Date();
        if (this.translation_status && codes.indexOf(this.translation_status.code) > 0) {
            // proofreading
            if (this.proofreader_deadline) {
                if (this.proofreader_deadline.getTime() > now.getTime()) {
                    return (this.proofreader_deadline.getTime() - now.getTime()) / (1000 * 60) < 60;
                }
            }
        }
        else {
            // translation
            if (this.translator_deadline) {
                if (+this.translator_deadline > +now && (+this.proofreader_deadline - +now) < 3600) {
                    return (this.translator_deadline.getTime() - now.getTime()) / (1000 * 60) < 60;
                }
            }
        }
        return false;
    };
    Task.prototype.isOutOfDate = function () {
        var codes = ['FINISHED', 'RECEIVED'];
        var now = new Date();
        if (this.translation_status && codes.indexOf(this.translation_status.code) > 0) {
            // proofreading
            if (this.proofreader_deadline) {
                if (+this.proofreader_deadline <= +now) {
                    return true;
                }
            }
        }
        else {
            // translation
            if (this.translator_deadline) {
                if (this.id == 1129) {
                    //console.log(this.id, '==>', this.translator_deadline);
                    //console.log(now)
                    //console.log(+this.translator_deadline, 'vs', +now)
                }
                if (+this.translator_deadline <= +now) {
                    //console.log('ici')
                    return true;
                }
            }
        }
        return false;
    };
    tslib_1.__decorate([
        Relation(Supplier),
        tslib_1.__metadata("design:type", Supplier)
    ], Task.prototype, "translator", void 0);
    tslib_1.__decorate([
        DateDef(),
        tslib_1.__metadata("design:type", Date)
    ], Task.prototype, "translator_deadline", void 0);
    tslib_1.__decorate([
        Relation(TaskStatus),
        tslib_1.__metadata("design:type", TaskStatus)
    ], Task.prototype, "translation_status", void 0);
    tslib_1.__decorate([
        Relation(Supplier),
        tslib_1.__metadata("design:type", Supplier)
    ], Task.prototype, "proofreader", void 0);
    tslib_1.__decorate([
        DateDef(),
        tslib_1.__metadata("design:type", Date)
    ], Task.prototype, "proofreader_deadline", void 0);
    tslib_1.__decorate([
        Relation(TaskStatus),
        tslib_1.__metadata("design:type", TaskStatus)
    ], Task.prototype, "proofreading_status", void 0);
    tslib_1.__decorate([
        DateDef(),
        tslib_1.__metadata("design:type", Date)
    ], Task.prototype, "www_updated_at", void 0);
    return Task;
}());
export { Task };
