<div>
	<bs-datepicker-inline [(bsValue)]="value"
						  [datesDisabled]="datesDisabled"
						  [isDisabled]="isDisabled"
						  [maxDate]="maxDate"
						  [minDate]="minDate">
	</bs-datepicker-inline>
	<timepicker [(ngModel)]="value"
				[arrowkeys]="arrowkeys"
				[hoursPlaceholder]="hoursPlaceholder"
				[hourStep]="hourStep"
				[meridians]="meridians"
				[minutesPlaceholder]="minutesPlaceholder"
				[minuteStep]="minuteStep"
				[mousewheel]="mousewheel"
				[readonlyInput]="readonlyInput"
				[secondsPlaceholder]="secondsPlaceholder"
				[secondsStep]="secondsStep"
				[showMeridian]="showMeridian"
				[showMinutes]="showMinutes"
				[showSeconds]="showSeconds"
				[showSpinners]="showSpinners"></timepicker>
	<div class="buttons clearfix">
		<div class="btn-group float-left">
			<button class="btn btn"
					(click)="now($event)">{{ nowLabel }}</button>
		</div>
		<div class="btn-group float-right">
			<button class="btn btn-primary"
					(click)="save($event)">{{ saveLabel }}</button>
		</div>
	</div>
</div>