import { AppConfigService } from '../app-config.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ObjectHydratorService } from '@app/modules/shared/services/object-hydrator.service';
import { isArray } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app-config.service";
import * as i3 from "../../modules/shared/services/object-hydrator.service";
var Model = /** @class */ (function () {
    function Model() {
    }
    return Model;
}());
//type Filters = { [key: string]: string | number | boolean };
function filtersToStr(filters) {
    //console.log('filters : ', filters)
    var str = [];
    if (filters.page || filters.page === 0) {
        str.push('page=' + filters.page);
    }
    if (filters.pageSize) {
        str.push('pageSize=' + filters.pageSize);
    }
    if (filters.sortColumn) {
        var direction = filters.sortDirection || 'asc';
        str.push('sort=' + filters.sortColumn + ':' + direction);
    }
    if (filters.filter) {
        var filter = JSON.parse(filters.filter);
        var filterStr = Array.from(Object.entries(filter))
            .map(function (item) { return item[0] + ':' + item[1]; })
            .join(',');
        str.push('filter=' + filterStr);
    }
    /*const str1 = Array
        .from(Object.entries(filters))
        .map(filter => {
            if (isObject(filter[1])) {
                filter[1] = Array.from(Object.entries(filter[1])).map(value => value[0]+':'+value[1]).join(',');
            }
            return filter;
        })
        .map(filter => filter[0]+'='+filter[1])
        .join('&');*/
    return str.length ? '?' + str.join('&') : '';
}
var Pagination = /** @class */ (function () {
    function Pagination() {
    }
    return Pagination;
}());
export { Pagination };
/**
 * Simple interface to communicate with the backend
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
var ApiService = /** @class */ (function () {
    function ApiService(http, appConfig, hydrator) {
        this.http = http;
        this.appConfig = appConfig;
        this.hydrator = hydrator;
        this.backendBaseUrl = appConfig.get('backendBaseUrl');
    }
    /**
     * Gets a list of an entity type
     * @param c the type of the entity
     * @param url the url to fetch the entities
     * @returns a promise with a list of object
     */
    ApiService.prototype.list = function (c, url, queryParams) {
        var _this = this;
        if (queryParams === void 0) { queryParams = null; }
        var options = {};
        if (queryParams) {
            options.params = queryParams;
        }
        return this.http.get("" + this.backendBaseUrl + url, options)
            .pipe(map(function (data) {
            var items = [];
            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                var dataItem = data_1[_i];
                var item = _this.hydrator.hydrate(c, dataItem);
                //const item = new c();
                //Object.assign(item, dataItem);
                items.push(item);
            }
            return items;
        }))
            .toPromise();
    };
    /**
     * Gets an entity from the backend
     * @param c th eclass of the entity to get
     * @param url the url of the backend
     * @param the id of the entity to fetch
     * @returns A promise of the entity
     */
    ApiService.prototype.get = function (c, url, id) {
        var _this = this;
        return this.http.get("" + this.backendBaseUrl + url)
            // Convert result to the entity
            .pipe(map(function (data) {
            var item = _this.hydrator.hydrate(c, data);
            return item;
            /*const item = new c();
            Object.assign(item, data);
            return item;*/
        }))
            .toPromise();
    };
    /**
     * Deletes an entity from the backend
     * @param c the class of the entity
     * @param url the url of the backend
     * @param object the object to delete
     * @returns a promise of the entity
     */
    ApiService.prototype.delete = function (c, url, object) {
        if (!object.id) {
            throw new Error('Cant\'t delete an entity without id');
        }
        return this.http.delete("" + this.backendBaseUrl + url)
            .pipe(map(function (data) {
            var item = new c();
            Object.assign(item, data);
            return item;
        }))
            .toPromise();
    };
    /**
     * Creates a new entity on the backend.
     * @param entity the user to create
     * @returns a Promise with the new user (with an id)
     * @throws {Error} if the user already has an id.
     */
    ApiService.prototype.create = function (c, url, object) {
        if (object.id) {
            throw new Error('Can\'t create an entity with an id');
        }
        return this.http.post("" + this.backendBaseUrl + url, object)
            .pipe(map(function (data) {
            var item = new c();
            Object.assign(item, data);
            return item;
        }))
            .toPromise();
    };
    /**
     * Updates an entity on the backend.
     * @param c the class name of the entity
     * @param url the url of the api
     * @param entity the entity to send to the api
     * @returns a Promise with the updated entity (with an id)
     * @throws {Error} if the entity already has an id.
     */
    ApiService.prototype.update = function (c, url, object) {
        if (!object.id) {
            throw new Error('Can\'t update an entity without an id');
        }
        return this.http.put("" + this.backendBaseUrl + url, object)
            .pipe(map(function (data) {
            var item = new c();
            Object.assign(item, data);
            return item;
        }))
            .toPromise();
    };
    /**
     * Updates multiples entities on the backend.
     * @param c the class name of the entity
     * @param url the url of the api
     * @param entity the entity to send to the api
     * @returns a Promise with the updated entity (with an id)
     * @throws {Error} if the entity already has an id.
     */
    ApiService.prototype.updateAll = function (c, url, objects) {
        for (var _i = 0, objects_1 = objects; _i < objects_1.length; _i++) {
            var object = objects_1[_i];
            if (!object.id) {
                throw new Error('Can\'t update an entity without an id');
            }
        }
        return this.http.put("" + this.backendBaseUrl + url, objects)
            .pipe(map(function (data) {
            return data.map(function (object) {
                var item = new c();
                Object.assign(item, object);
                return item;
            });
        }))
            .toPromise();
    };
    /**
     * Get the history of an entity
     * @param url the url of the backend
     * @returns a promise of the log entries
     */
    ApiService.prototype.history = function (c, url) {
        var _this = this;
        return this.http.get("" + this.backendBaseUrl + url)
            .pipe(map(function (data) {
            var entries = [];
            for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                var entryData = data_2[_i];
                var entry = _this.hydrator.hydrate(c, entryData);
                entries.push(entry);
            }
            return entries;
        }))
            .toPromise();
    };
    /**
     * Call a custom api
     * @param c the class name of the entity
     * @param method the method. Possible values : 'PUT', 'POST', 'PATCH', 'GET' and 'DELETE
     * @param url the url of the api
     * @param object an object to send to the api (only with 'PUT', 'POST' and 'PATCH' methods)
     * @returns a promise with an object or a list of objects
     */
    ApiService.prototype.custom = function (c, method, url, object) {
        var _this = this;
        var options = {};
        if ((method === 'PUT' || method === 'POST' || method === 'PATCH')
            && !object) {
            throw new Error("should set an object with put, post or path methods");
        }
        if (object) {
            options.body = object;
        }
        return this.http.request(method, "" + this.backendBaseUrl + url, options)
            .pipe(map(function (res) {
            var data = res;
            if (isArray(data)) {
                var items = [];
                for (var _i = 0, data_3 = data; _i < data_3.length; _i++) {
                    var dataItem = data_3[_i];
                    var item = _this.hydrator.hydrate(c, dataItem);
                    items.push(item);
                }
                return items;
            }
            else {
                return _this.hydrator.hydrate(c, data);
            }
        }))
            .toPromise();
    };
    /**
     * Gets a list of an entity type
     * @param c the type of the entity
     * @param url the url to fetch the entities
     * @param filters the optional filters
     * @returns a promise with a list of object
     */
    ApiService.prototype.listPaginate = function (c, url, filters) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        return this.http.get("" + this.backendBaseUrl + url + filtersToStr(filters))
            .pipe(map(function (data) {
            var pagination = new Pagination();
            pagination.total = data.total;
            pagination.page = data.page;
            pagination.page_size = data.page_size;
            pagination.results = [];
            for (var _i = 0, _a = data.results; _i < _a.length; _i++) {
                var dataItem = _a[_i];
                var item = _this.hydrator.hydrate(c, dataItem);
                pagination.results.push(item);
            }
            return pagination;
        }))
            .toPromise();
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.ObjectHydratorService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
