import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetValidateComponent } from './pages/password-reset-validate/password-reset-validate.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdministrationComponent } from './pages/administration/administration.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';

export const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'login_page_title',
			animation: 'Login'
		}
	},
	{
		path: 'password-reset',
		component: PasswordResetComponent,
		data: {
			title: 'Reset password',
			animation: 'PasswordReset'
		}
	},
	{
		path: 'password-reset-validate/:email',
		component: PasswordResetValidateComponent,
		data: {
			animation: 'PasswordResetValidate'
		}
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
		data: {
			animation: 'Dashboard'
		},
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
			animation: 'Page404'
		}
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
			animation: 'Page500'
		}
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		canActivate: [
			AuthGuardService
		],
		children: [{
			path: 'dashboard',
			component: DashboardComponent,
			data: {
				title: 'dashboard.page_title',
				animation: 'Dashboard'
			}
		}, {
			path: 'changelog',
			component: ChangelogComponent,
			data: {
				title: 'changelog',
				animation: 'Changelog'
			}
		}, {
			path: 'users',
			loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
		}, {
			path: 'customers',
			loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
		},
		{
			path: 'suppliers',
			loadChildren: () => import('./modules/supplier/supplier.module').then(m => m.SupplierModule)
		},
		{
			path: 'projects',
			loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
		},
		{
			path: 'purchase-orders',
			loadChildren: () => import('./modules/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule)
		},
		{
			path: 'administration',
			pathMatch: 'full',
			component: AdministrationComponent,
			data: {
				title: 'administration.breadcrumb'
			}
		}, {
			path: 'administration',
			data: {
				title: 'administration.breadcrumb'
			},
			children: [{
				path: 'users',
				data: {
					title: 'user.list.breadcrumb'
				},
				loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
			}]
		}]
	},
	{ path: '**', component: P404Component, data: { animation: 'Default' } },

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
