import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './api/auth.service';
import { Observable, throwError, of } from 'rxjs';
import { fakeAsync } from '@angular/core/testing';
import { map, take, catchError } from 'rxjs/operators';

/**
 * Guard that canActivate if the user is authenticated.
 *
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	/**
	 * Constructs the Guard
	 * @param auth
	 * @param router
	 */
	constructor(private auth: AuthService, private router: Router) {}

	/**
	 * Test if the user is authenticated
	 * @returns An observable resolving into true if the user is authenticated, false otherwise
	 */
	canActivate(): Observable<boolean> {
		return new Observable(observer => {
			this.auth.getAuthenticatedUser().subscribe(user => {
				observer.next(true);
				observer.complete();
			}, err => {
				this.router.navigate(['login']);
				observer.next(false);
				observer.complete();
			});
		});
	}
}
