import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '@app/services/api/api.service';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

@Component({
	selector: 'app-global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

	search: string;
	suggestions$: Observable<any[]>;

	constructor(
		private apiService: ApiService,
		private router: Router
	) { }

	ngOnInit() {
		this.suggestions$ = new Observable((observer: Observer<string>) => {
			observer.next(this.search)
		}).pipe(switchMap((query: string) => {
			if (query) {
				return this.apiService.list(Object, `/search`, {
					q: query
				});
			}

			return of([]);

		}));
	}

	onSelect(event: TypeaheadMatch): void {
		this.search = '';
		switch (event.item._type) {
			case 'customer':
				this.router.navigate(['/customers', event.item.id]);
				break;
			case 'supplier':
				this.router.navigate(['/suppliers', event.item.id]);
				break;
			case 'project':
				this.router.navigate(['/projects', event.item.id]);
				break;
			case 'task':
				this.router.navigate(['/projects', event.item.project.id, 'tasks', event.item.id]);
				break;
		}
	}

}
