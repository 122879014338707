import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '../../services/api/auth.service';
import { skipWhile } from 'rxjs/operators';

/**
 * Represents the main layout of the app (only visible when logged)
 */
@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
	/** The items of the left nav */
	public navItems = [...navItems];
	/** Indicates if the sidebar is minimized */
	public sidebarMinimized = true;
	/** A change observer (for the minimization of the sidebar when the class `sidebar-minized` is added) */
	private changes: MutationObserver;
	/** The body tag */
	public element: HTMLElement;

	public user: any;

	/**
	 * Constructs the component
	 * @param _document
	 */
	constructor(transloco: TranslocoService, private authService: AuthService, @Inject(DOCUMENT) _document?: any) {

		for (const index of Object.keys(navItems)) {
			if (!navItems[index].translated) {
				navItems[index].name = transloco.translate(navItems[index].name);
				navItems[index].translated = true;
			}
		}

		this.changes = new MutationObserver((mutations) => {
			this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
		});
		this.element = _document.body;
		this.changes.observe(<Element>this.element, {
			attributes: true,
			attributeFilter: ['class']
		});

		this.authService.authenticatedUser$.subscribe(user => {
			this.user = user;

			if (!user) {
				return;
			}

			this.navItems = [];
			for (const index of Object.keys(navItems)) {
				if (navItems[index].permissions) {
					let canDisplay = false;
					for(const permission of navItems[index].permissions) {
						if (user.hasPermission(permission)) {
							canDisplay = true;
							break;
						}
					}
					if (canDisplay) {
						this.navItems.push(navItems[index]);
						//console.log('dont display ', this.navItems[index]);
						//delete(this.navItems)
					}
				} else {
					this.navItems.push(navItems[index]);
				}
			}
		});
	}

	/**
	 * Callback when the component is removed
	 */
	ngOnDestroy(): void {
		this.changes.disconnect();
	}
}
