import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Intercepts any HttpRequest and checks if response has a 401 response error.
 * It it the case, it redirect to the login page
 */
var AuthInterceptorService = /** @class */ (function () {
    function AuthInterceptorService(router) {
        this.router = router;
    }
    AuthInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (err) {
            if (err.status === 401) {
                _this.router.navigate(['login']);
                return throwError(err);
            }
            else {
                return throwError(err);
            }
        }));
    };
    AuthInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.ɵɵinject(i1.Router)); }, token: AuthInterceptorService, providedIn: "root" });
    return AuthInterceptorService;
}());
export { AuthInterceptorService };
