<h1>Changelog</h1>
<div class="changelog-entry"
     *ngFor="let entry of changelog">
    <h2>{{ entry.version }}</h2>
    <div class="text-seconday">{{ entry.date | date:'shortDate' }}</div>

    <div class="changelog-entry-section"
         *ngFor="let section of entry.sections | keyvalue: originalOrder">
        <h3>{{ section.key }}</h3>
        <ul>
            <li *ngFor="let action of section.value">
                <i class="fas fa-bug"
                   *ngIf="action.type == 'fix'"></i>
                <i class="fas fa-wrench"
                   *ngIf="action.type == 'feature'"></i>
                {{ action.title }}
            </li>
        </ul>
    </div>
</div>