import { FormGroup } from '@angular/forms';

/**
 * Form Validator to test the equality between two fields.
 *
 ```ts
 this.formBuilder.group({
	field1: ['', Validators.required],
	field2: ['', Validators.required]
 }, {
	validator: MatchValues('field1', 'field2')
 })
 ```
 * If the validation fails, it sets `errors.matchValues` to true
 *
 * @param {string} firstControlName The name of the first control
 * @param {string} secondControlName The name of the second control
 * @returns {function} a function accepting a parameter
 *
 */
export function MatchValues(
	firstControlName: string,
	secondControlName: string
) {
	return function(formGroup: FormGroup): {[key: string]: boolean} | null {
		let first: any = formGroup;
		for(let name of firstControlName.split('.')) {
			first = first.get(name);
		}

		let second: any = formGroup;
		for(let name of secondControlName.split('.')) {
			second = second.get(name);
		}

		if (!first || !second) {
			return null;
		}

		if (second.errors && !second.errors.matchValues) {
			return null;
		}

		if (first.value !== second.value) {
			second.setErrors({ matchValues: true });
		} else {
			second.setErrors(null);
		}
	};
}
