import { Supplier } from '@app/modules/supplier/models/supplier';
import { Relation, DateDef } from '@app/modules/shared/services/object-hydrator.service';
import { Language } from '@app/modules/shared/models/language';
import { TaskStatus } from './task-status';
import { User } from '@app/models/user';

export class Task {
	id: number;
	moravia_id: string;
	name: string;

	project: any;

	// Translator
	@Relation(Supplier) translator: Supplier;
	@DateDef() translator_deadline: Date;
	@Relation(TaskStatus) translation_status: TaskStatus;
	translator_is_delivered: boolean;
	// Proofreader
	@Relation(Supplier) proofreader: Supplier;
	@DateDef() proofreader_deadline: Date;
	@Relation(TaskStatus) proofreading_status: TaskStatus;
	proofreading_is_delivered: boolean;

	sourceLanguage: Language;
	targetLanguage: Language;

	word_count: number;
	wwc: number;
	hours: number;
	wwc_updated: number;
	wwc_is_handmade: boolean;
	@DateDef() www_updated_at: Date;

	clone(): Task {
		const clone = new Task();
		clone.id = this.id;
		clone.moravia_id = this.moravia_id;
		clone.name = this.name;
		clone.project = this.project;

		clone.translator = this.translator;
		clone.translator_deadline = this.translator_deadline;
		clone.translation_status = this.translation_status;

		clone.proofreader = this.proofreader;
		clone.proofreader_deadline = this.proofreader_deadline;
		clone.proofreading_status = this.proofreading_status;

		clone.sourceLanguage = this.sourceLanguage;
		clone.targetLanguage = this.targetLanguage;

		clone.word_count = this.word_count;
		clone.wwc = this.wwc;
		clone.hours = this.hours;
		clone.wwc_updated = this.wwc_updated;
		clone.wwc_is_handmade = this.wwc_is_handmade;
		return clone;
	}

	toString() {
		return this.moravia_id;
	}

	getMoraviaUrl() {
		return 'https://projects.moravia.com/task/' + this.moravia_id + '/attachments';
	}

	hasManager(user: User) {
		for(let customer of user.customers) {
			if (this.project.customer.id == customer.id) {
				return true;
			}
		}
		return false;
	}

	isClosedToDate() {
		let codes = ['FINISHED', 'RECEIVED'];
		const now = new Date();

		if (this.translation_status && codes.indexOf(this.translation_status.code) > 0) {
			// proofreading
			if (this.proofreader_deadline) {
				if (this.proofreader_deadline.getTime() > now.getTime()) {
					return (this.proofreader_deadline.getTime() - now.getTime()) / (1000 * 60) < 60;
				}
			}
		} else {
			// translation
			if (this.translator_deadline) {
				if (+this.translator_deadline > +now && (+this.proofreader_deadline - +now) < 3600) {
					return (this.translator_deadline.getTime() - now.getTime()) / (1000 * 60) < 60;
				}
			}
		}
		return false;
	}

	isOutOfDate() {
		let codes = ['FINISHED', 'RECEIVED'];
		const now = new Date();

		if (this.translation_status && codes.indexOf(this.translation_status.code) > 0) {
			// proofreading
			if (this.proofreader_deadline) {
				if (+this.proofreader_deadline <= +now) {
					return true;
				}
			}
		} else {
			// translation
			if (this.translator_deadline) {
				if (this.id == 1129) {
					//console.log(this.id, '==>', this.translator_deadline);
					//console.log(now)
					//console.log(+this.translator_deadline, 'vs', +now)
				}
				if (+this.translator_deadline <= +now) {
					//console.log('ici')
					return true;
				}
			}
		}
		return false;
	}
}