<ng-container *transloco="let t;">

	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ t('history.title') }}</h4>
		<button type="button"
				class="close pull-right"
				aria-label="Close"
				(click)="close($event)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<ul class="timeline">
			<li class="entry"
				*ngFor="let logEntry of data.history; let i = index"
				[class.timeline-inverted]="i % 2 == 1">
				<div class="timeline-badge">
					<i class="fa fa-edit"
					   *ngIf="logEntry.action == 'update'"></i>
					<i class="fa fa-plus"
					   *ngIf="logEntry.action == 'create'"></i>
					<i class="fa fa-trash"
					   *ngIf="logEntry.action == 'remove'"></i>
					<i class="fas fa-link"
					   *ngIf="logEntry.action == 'associat'"></i>
					<i class="fas fa-unlink"
					   *ngIf="logEntry.action == 'dissocia'"></i>
				</div>
				<div class="timeline-panel">
					<div class="timeline-heading">
						<h4 class="timeline-title">
							<span *ngIf="logEntry.action == 'update'">{{ t('log_entry.update') }}</span>
							<span *ngIf="logEntry.action == 'create'">{{ t('log_entry.create') }}</span>
							<span *ngIf="logEntry.action == 'remove'">{{ t('log_entry.remove') }}</span>
							<span *ngIf="logEntry.action == 'associat'">{{ t('log_entry.associate') }}</span>
							<span *ngIf="logEntry.action == 'dissocia'">{{ t('log_entry.dissociate') }}</span>
						</h4>
						<p><small class="text-muted"><i class="far fa-clock"></i>
								{{ t('log_entry.by', {
									date: logEntry.logged_at | date:'medium',
									author: logEntry.user.getFullName()
								}) }}</small>
						</p>
					</div>
					<div class="timeline-body">
			<li *ngFor="let item of logEntry.data | keyvalue"
				[ngSwitch]="getValueType(item.value)">

				<span *ngSwitchCase="'array'">
					<span *ngIf="item.value.length === 0"></span>
					<span *ngIf="item.value.length > 0">{{ t(data.type+'.fields.'+item.key) }} : {{ item.value.length }}</span>
				</span>
				<span *ngSwitchCase="'boolean'">
					{{ t(data.type+'.fields.'+item.key) }} :
					<i class="fa fa-check"
					   [attr.fa-check]="item.value"
					   [attr.fa-times]="!item.value"></i>
				</span>
				<span *ngSwitchCase="'date'">
					{{ t(data.type+'.fields.'+item.key) }} :
					{{ item.value | date:'short' }}
				</span>
				<span *ngSwitchDefault [ngSwitch]="item._type">
					{{ t(data.type+'.fields.'+item.key) }} :
					<span *ngIf="getObjectLink(item.value)">
						<a [routerLink]="getObjectLink(item.value)"> {{ item.value }} </a>
					</span>
					<span *ngIf="!getObjectLink(item.value)">
						{{ item.value }}
					</span>
				</span>

			</li>
	</div>
	</div>
	</li>
	</ul>
	</div>

	<div class="modal-footer">
		<button class="btn btn-primary"
				(click)="close()">{{ t('form.close') }}</button>
	</div>
</ng-container>