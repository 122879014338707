import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, ObservableInput, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../api/auth.service';

@Injectable({
	providedIn: 'root'
})
export class TokenAuthenticationInterceptorService implements HttpInterceptor {

	constructor(
		private router: Router,
		private auth: AuthService
	) { }

	intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	public async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const token = await localStorage.getItem('token');

		if (token) {
			request = request.clone({
				setHeaders: {
					//Authorization: `X-AUTH-TOKEN ${token}`
					'X-AUTH-TOKEN': token
				}
			});
		}

		return next.handle(request).toPromise();
	}
}