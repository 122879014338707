import { Routes } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetValidateComponent } from './pages/password-reset-validate/password-reset-validate.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdministrationComponent } from './pages/administration/administration.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
var ɵ0 = {
    title: 'login_page_title',
    animation: 'Login'
}, ɵ1 = {
    title: 'Reset password',
    animation: 'PasswordReset'
}, ɵ2 = {
    animation: 'PasswordResetValidate'
}, ɵ3 = {
    animation: 'Dashboard'
}, ɵ4 = {
    title: 'Page 404',
    animation: 'Page404'
}, ɵ5 = {
    title: 'Page 500',
    animation: 'Page500'
}, ɵ6 = {
    title: 'dashboard.page_title',
    animation: 'Dashboard'
}, ɵ7 = {
    title: 'changelog',
    animation: 'Changelog'
}, ɵ8 = function () { return import("./modules/user/user.module.ngfactory").then(function (m) { return m.UserModuleNgFactory; }); }, ɵ9 = function () { return import("./modules/customer/customer.module.ngfactory").then(function (m) { return m.CustomerModuleNgFactory; }); }, ɵ10 = function () { return import("./modules/supplier/supplier.module.ngfactory").then(function (m) { return m.SupplierModuleNgFactory; }); }, ɵ11 = function () { return import("./modules/project/project.module.ngfactory").then(function (m) { return m.ProjectModuleNgFactory; }); }, ɵ12 = function () { return import("./modules/purchase-order/purchase-order.module.ngfactory").then(function (m) { return m.PurchaseOrderModuleNgFactory; }); }, ɵ13 = {
    title: 'administration.breadcrumb'
}, ɵ14 = {
    title: 'administration.breadcrumb'
}, ɵ15 = {
    title: 'user.list.breadcrumb'
}, ɵ16 = function () { return import("./modules/user/user.module.ngfactory").then(function (m) { return m.UserModuleNgFactory; }); }, ɵ17 = { animation: 'Default' };
export var routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        data: ɵ1
    },
    {
        path: 'password-reset-validate/:email',
        component: PasswordResetValidateComponent,
        data: ɵ2
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: '404',
        component: P404Component,
        data: ɵ4
    },
    {
        path: '500',
        component: P500Component,
        data: ɵ5
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [
            AuthGuardService
        ],
        children: [{
                path: 'dashboard',
                component: DashboardComponent,
                data: ɵ6
            }, {
                path: 'changelog',
                component: ChangelogComponent,
                data: ɵ7
            }, {
                path: 'users',
                loadChildren: ɵ8
            }, {
                path: 'customers',
                loadChildren: ɵ9
            },
            {
                path: 'suppliers',
                loadChildren: ɵ10
            },
            {
                path: 'projects',
                loadChildren: ɵ11
            },
            {
                path: 'purchase-orders',
                loadChildren: ɵ12
            },
            {
                path: 'administration',
                pathMatch: 'full',
                component: AdministrationComponent,
                data: ɵ13
            }, {
                path: 'administration',
                data: ɵ14,
                children: [{
                        path: 'users',
                        data: ɵ15,
                        loadChildren: ɵ16
                    }]
            }]
    },
    { path: '**', component: P404Component, data: ɵ17 },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
