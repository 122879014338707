import { HttpClient } from '@angular/common/http';
import { TRANSLOCO_LOADER } from '@ngneat/transloco';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Represents the loader of the translations
 */
var HttpLoader = /** @class */ (function () {
    /**
     * Constructs the loader
     * @param http
     */
    function HttpLoader(http) {
        this.http = http;
    }
    /**
     * Gets the translations for a given language
     * @param langPath
     * @returns the translation
     */
    HttpLoader.prototype.getTranslation = function (langPath) {
        return this.http.get("/assets/i18n/" + langPath + ".json")
            .pipe(catchError(function (e, b) {
            console.log('ERROR : ', e);
            return throwError(e);
        }));
    };
    HttpLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpLoader_Factory() { return new HttpLoader(i0.ɵɵinject(i1.HttpClient)); }, token: HttpLoader, providedIn: "root" });
    return HttpLoader;
}());
export { HttpLoader };
/**
 * The Transloco provider
 */
export var translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
