/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toastr.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toastr.component";
import * as i4 from "ngx-toastr";
var styles_ToastrComponent = [i0.styles];
var RenderType_ToastrComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastrComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "300ms ease-out" }, options: null }, { type: 0, name: "active", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "active => removed", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "300ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_ToastrComponent as RenderType_ToastrComponent };
function View_ToastrComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "toast-progress"]], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.width + "%"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ToastrComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "toaster-icon icon-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "toast-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "toast-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "toast-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "ng-star-inserted"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastrComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.options.progressBar; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.value === "inactive") ? "none" : ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_ToastrComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toastr", [], [[8, "className", 0], [40, "@flyInOut", 0], [4, "display", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ToastrComponent_0, RenderType_ToastrComponent)), i1.ɵdid(1, 180224, null, 0, i3.ToastrComponent, [i4.ToastrService, i4.ToastPackage], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toastClasses; var currVal_1 = i1.ɵnov(_v, 1).state; var currVal_2 = i1.ɵnov(_v, 1).displayStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var ToastrComponentNgFactory = i1.ɵccf("app-toastr", i3.ToastrComponent, View_ToastrComponent_Host_0, {}, {}, []);
export { ToastrComponentNgFactory as ToastrComponentNgFactory };
