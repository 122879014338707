import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../api/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../api/auth.service";
var TokenAuthenticationInterceptorService = /** @class */ (function () {
    function TokenAuthenticationInterceptorService(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    TokenAuthenticationInterceptorService.prototype.intercept = function (request, next) {
        return from(this.handleAccess(request, next));
    };
    TokenAuthenticationInterceptorService.prototype.handleAccess = function (request, next) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, localStorage.getItem('token')];
                    case 1:
                        token = _a.sent();
                        if (token) {
                            request = request.clone({
                                setHeaders: {
                                    //Authorization: `X-AUTH-TOKEN ${token}`
                                    'X-AUTH-TOKEN': token
                                }
                            });
                        }
                        return [2 /*return*/, next.handle(request).toPromise()];
                }
            });
        });
    };
    TokenAuthenticationInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenAuthenticationInterceptorService_Factory() { return new TokenAuthenticationInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: TokenAuthenticationInterceptorService, providedIn: "root" });
    return TokenAuthenticationInterceptorService;
}());
export { TokenAuthenticationInterceptorService };
