// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeFrBE from '@angular/common/locales/fr-BE';
import localeFrBEExtra from '@angular/common/locales/extra/fr-BE';

// Core UI
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';
import { LoginComponent as DefaultLoginComponent } from './views/login/login.component';
import {
	AppAsideModule,
	AppHeaderModule,
	AppFooterModule,
	AppSidebarModule,
} from '@coreui/angular';
// Libs
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LaddaModule } from 'angular2-ladda';
import { translocoLoader } from './transloco.loader';
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoService, TRANSLOCO_TRANSPILER } from '@ngneat/transloco';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

// App
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { AppConfigService } from './services/app-config.service';
import { DefaultLayoutComponent } from './containers';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { LoginComponent } from './pages/login/login.component';
import { AppComponent } from './app.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetValidateComponent } from './pages/password-reset-validate/password-reset-validate.component';
import { AuthService } from './services/api/auth.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthInterceptorService } from './services/auth/auth.interceptor.service';
import { SharedModule } from './modules/shared/shared.module';
import { ToastrComponent } from './modules/shared/components/toastr/toastr.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { DeepTranspiler } from './transloco.transpiler';
import { DatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { StorageService } from './services/storage.service';
import { TokenAuthenticationInterceptorService } from './services/auth/token-authentication-interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HotkeyModule } from 'angular2-hotkeys';
import { ChangelogComponent } from './pages/changelog/changelog.component';

registerLocaleData(localeFrBE, 'fr-BE', localeFrBEExtra);

/**
 * Loads the configuration using the AppConfig service
 * @param appConfig
 */
export function appInitialized(appConfig: AppConfigService) {
	return (): Promise<any> => appConfig.load();
}

/**
 * Preload the translator before the app is initialised so that the
 * translations are available at app start.
 * @param transloco the transloco service
 * @reutrns a function to run to load the `fr` language
 */
export function preloadTranslations(transloco: TranslocoService) {
	return function() {
		return transloco.load('fr').toPromise();
	};
}

//frLocale.longDateFormat.LLTS = 'DD-MM-YYYY HH:mm:ss';
defineLocale('fr', frLocale);
export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		dateInputFormat: 'L LT'
	});
}

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		AppAsideModule,
		AppFooterModule,
		AppHeaderModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ChartsModule,
		// Ladda
		LaddaModule.forRoot({
			style: 'slide-left'
		}),
		ToastrModule.forRoot({
			toastComponent: ToastrComponent,
			timeOut: 5000,
			progressBar: true
		}),
		DatepickerModule.forRoot(),
		NgSelectModule,
		TranslocoModule,
		// for HttpClient use:
		LoadingBarHttpClientModule,
		// for Router use:
		LoadingBarRouterModule,
		PasswordStrengthMeterModule,
		SharedModule,
		NgxMaskModule.forRoot(),
		ModalModule.forRoot(),
		TypeaheadModule.forRoot(),
		HotkeyModule.forRoot()
	],
	declarations: [
		AppComponent,
		DefaultLayoutComponent,
		P404Component,
		P500Component,
		DefaultLoginComponent,
		LoginComponent,
		RegisterComponent,
		PasswordResetComponent,
		PasswordResetValidateComponent,
		DashboardComponent,
		BreadcrumbComponent,
		AdministrationComponent,
		GlobalSearchComponent,
		ChangelogComponent
	],
	providers: [{
		provide: LocationStrategy,
		useClass: HashLocationStrategy,
	}, {
		provide: LOCALE_ID,
		useValue: 'fr-BE'
	}, AuthService, AppConfigService, {
		provide: APP_INITIALIZER,
		useFactory: appInitialized,
		multi: true,
		deps: [AppConfigService]
	}, translocoLoader, {
		provide: TRANSLOCO_CONFIG,
		useValue: {
			availableLangs: ['fr'],
			prodMode: environment.production,
			defaultLang: 'fr',
			fallbackLang: 'fr'
		} as TranslocoConfig
	}, {
		provide: APP_INITIALIZER,
		useFactory: preloadTranslations,
		multi: true,
		deps: [TranslocoService]
	}, {
		provide: TRANSLOCO_TRANSPILER,
		useClass: DeepTranspiler
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: TokenAuthenticationInterceptorService,
		multi: true,
		deps: [StorageService]
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptorService,
		multi: true,
		deps: [Router]
	}, BsModalRef, {
		provide: BsDatepickerConfig,
		useFactory: getDatepickerConfig
	},
	BreadcrumbService],
	exports: [
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
