<div class="app-body">
	<main class="main d-flex align-items-center">
		<div class="container">
			<div class="row">
				<div class="col-md-10 mx-auto">
					<div class="card-group">
						<div class="card text-white py-5 d-md-down-none" style="width:44%">
							<div class="card-body text-center">
								<!--<div>
									<h2>Vous n'avez pas de compte ?</h2>
									<p>Demandez-en un à Marine !</p>
									<button type="button" class="btn btn-primary active mt-3">Demander un compte</button>
								</div>-->
								<img src="assets/img/brand/logo-medium.png" id="logo" alt="Logo de JDC" />
							</div>
						</div>
						<div class="card p-4">
							<ng-container *transloco="let t">
							<div class="card-body login">
								<form [formGroup]="form" (ngSubmit)="login()">
									<h1>{{ t('login.header') }}</h1>
									<p class="text-muted">{{ t('login.help') }}</p>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-user"></i></span>
										</div>
										<input type="text" class="form-control"
											[attr.placeholder]="t('login.username_placeholder')" autocomplete="username" name="username" formControlName="username" required>
									</div>
									<div class="input-group mb-4">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-lock"></i></span>
										</div>
										<input type="password" class="form-control"
										[attr.placeholder]="t('login.password_placeholder')" autocomplete="current-password" name="password" formControlName="password" required>
									</div>
									<div [ngSwitch]="error" *ngIf="error">
										<div role="alert" *ngSwitchCase="429" class="error alert alert-danger" @errorAnimation >{{ t('login.too_many_login_attempts_error') }}</div>
										<div role="alert" *ngSwitchDefault class="error alert alert-danger" @errorAnimation >{{ t('login.bad_credential_error') }}</div>
									</div>

									<div class="row">
										<div class="col-6">
											<button type="submit" class="btn submit btn-primary px-4" [disabled]="!form.valid" [ladda]="isLoading">{{ t('login.submit_button') }}</button>
										</div>
										<div class="col-6 text-right">
											<button type="button" [routerLink]="['/password-reset']" class="btn btn-link px-0">{{ t('login.lost_password') }}</button>
										</div>
									</div>
								</form>
							</div>
							</ng-container>
						</div>

					</div>
				</div>
			</div>
		</div>
	</main>
</div>