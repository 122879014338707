import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Represents a service to manager a runtime configuration for the application.
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
var AppConfigService = /** @class */ (function () {
    /**
     * Initialises the service
     * @param http the http client
     */
    function AppConfigService(http) {
        this.http = http;
    }
    /**
     * Loads the configuration for an environment. The configuration should have the path `/assets/config.{env}.js`
     * @param env the environment of the configuration to load. If no environment is given,
     * it gets the environment name from the `environment.ts`
     * @returns a promise that is resolved when the config is loaded
     */
    AppConfigService.prototype.load = function (env) {
        var _this = this;
        if (env === void 0) { env = null; }
        env = env ? env : environment.name;
        var jsonFile = '/assets/config.' + env + '.json';
        return new Promise(function (resolve, reject) {
            _this.http.get(jsonFile).toPromise().then(function (response) {
                _this.config = response;
                resolve();
            }).catch(function (response) {
                reject("Could not load file '" + jsonFile + "': " + JSON.stringify(response));
            });
        });
    };
    /**
     * Gets all the configuration of a part
     * @param path the path of the configuration to get
     * @returns the configuratoin
     */
    AppConfigService.prototype.get = function (path) {
        if (path === void 0) { path = null; }
        if (path !== null) {
            return this.config[path];
        }
        return this.config;
    };
    AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.ɵɵinject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
