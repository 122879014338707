import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/api/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { state, style, animate, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { errorAnimation } from '../../animations/error.animation';

/**
 * Represents a component to authenticate the user in the app
 */
@Component({
	selector: 'app-dashboard',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [
		errorAnimation
	]
})
export class LoginComponent {

	/** Indicates if there is an error or not */
	public error: boolean;
	/** The main form */
	public form: FormGroup;
	/** Indicates if the request is running or not */
	public isLoading: boolean;

	/**
	 * Constructs the component
	 * @param auth
	 * @param formBuilder
	 * @param router
	 */
	constructor(private auth: AuthService, private formBuilder: FormBuilder, private router: Router) {
		this.error = false;
		this.isLoading = false;
		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});
		this.auth.logout().subscribe();
	}

	/**
	 * Log the user in.
	 * It send the username/password to authenticate the user.
	 * If the authentication succeed; the user is redirected to dashboard
	 * If the authentication fails, it displays the error
	 */
	login() {
		this.error = false;
		this.isLoading = true;
		this.auth.login(this.form.get('username').value, this.form.get('password').value).subscribe(result => {
			this.isLoading = false;
			this.router.navigateByUrl('');
		}, (err) => {
			this.isLoading = false;
			this.error = err.status;
		});
	}

}
