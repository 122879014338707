<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
	[navbarBrandFull]="{src: 'assets/img/brand/logo-horizontal.png', width: 125, height: 45, alt: 'Createivewords Logo'}"
	[navbarBrandMinimized]="{src: 'assets/img/brand/logo-small.png', width: 35, height: 35, alt: 'Createivewords Logo'}"
	[sidebarToggler]="'lg'" [asideMenuToggler]="'lg'">
	<ng-container *transloco="let t">
		<ul class="nav navbar-nav d-md-down-none">
			<li class="nav-item px-3">
				<app-global-search></app-global-search>
			</li>
			<li>
				<button class="btn btn-primary" [routerLink]="['/projects', 'add']">{{ t('project.list.add_project_button' )}}</button>
			</li>
		</ul>
		<ul class="nav navbar-nav ml-auto">
			<!--<li class="nav-item d-md-down-none">
				<a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
			</li>-->
			<li class="nav-item dropdown" dropdown placement="bottom right">
				<a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
					dropdownToggle (click)="false">
					<i class="fa fa-user" style="font-size: 20px;"></i>
					<span class="fullname d-md-down-none">{{user?.getFullName()}}</span>
					<!--<img src="assets/img/avatars/unknown.png" class="img-avatar" alt="avatar" />-->
				</a>
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<div class="dropdown-header text-center d-md-down-none"><strong>{{ user?.getFullName() }}</strong></div>
					<a class="dropdown-item" [routerLink]="['/users', user.id, 'details' ]"><i class="fa fa-user"></i> {{ t('layout.header.profile') }}</a>
					<a class="dropdown-item" [routerLink]="['/login']"><i class="fa fa-lock"></i> {{ t('layout.header.logout') }}</a>
				</div>
			</li>
		</ul>
	</ng-container>
</app-header>
<div class="app-body">
	<app-sidebar [fixed]="true" [display]="'lg'">
		<app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
		<app-sidebar-minimizer></app-sidebar-minimizer>
	</app-sidebar>
	<!-- Main content -->
	<main class="main">
		<!-- Breadcrumb -->
		<!-- breaking change 'cui-breadcrumb' -->
		<app-breadcrumb [fixed]="true">
			<!-- Breadcrumb Menu-->
			<!--<li class="breadcrumb-menu d-md-down-none">
				<div class="btn-group" role="group" aria-label="Button group with nested dropdown">
					<a class="btn" href="#"><i class="icon-speech"></i></a>
					<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
					<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
				</div>
			</li>-->
		</app-breadcrumb>
		<!-- deprecation warning for 'app-breadcrumb' -->
		<!--<ol class="breadcrumb">-->
		<!--<app-breadcrumb></app-breadcrumb>-->
		<!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
		<!--<li class="breadcrumb-menu d-md-down-none">-->
		<!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
		<!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
		<!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
		<!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
		<!--</div>-->
		<!--</li>-->
		<!--</ol>-->
		<div class="container-fluid">
			<router-outlet></router-outlet>
		</div><!-- /.container-fluid -->
	</main>
	<app-aside [fixed]="true" [display]="false">
		<tabset>
			<tab>
				<ng-template tabHeading><i class="icon-list"></i></ng-template>

			</tab>
			<tab>
				<ng-template tabHeading><i class="icon-speech"></i></ng-template>

			</tab>
			<tab>
				<ng-template tabHeading><i class="icon-settings"></i></ng-template>

			</tab>
		</tabset>
	</app-aside>
</div>
<app-footer [fixed]="true">
	<span>&copy; 2020 Creativewords. - BO <a [routerLink]="['/changelog']">0.5.0</a></span>
	<span class="ml-auto"></span>
</app-footer>