import * as tslib_1 from "tslib";
import { Relation, DateDef } from '@app/modules/shared/services/object-hydrator.service';
import { Supplier } from '@app/modules/supplier/models/supplier';
import { Status } from './status';
import { Customer } from '@app/modules/customer/models/customer';
import { Task } from './task';
import { Division } from '@app/modules/customer/models/division';
var Project = /** @class */ (function () {
    function Project() {
        this.tasks = [];
    }
    Project.prototype.toString = function () {
        return this.moravia_id;
    };
    Project.prototype.hasManager = function (user) {
        for (var _i = 0, _a = user.customers; _i < _a.length; _i++) {
            var customer = _a[_i];
            if (customer.id == this.customer.id) {
                return true;
            }
        }
        return false;
    };
    tslib_1.__decorate([
        Relation(Customer),
        tslib_1.__metadata("design:type", Customer)
    ], Project.prototype, "customer", void 0);
    tslib_1.__decorate([
        Relation(Supplier),
        tslib_1.__metadata("design:type", Supplier)
    ], Project.prototype, "translator", void 0);
    tslib_1.__decorate([
        Relation(Supplier),
        tslib_1.__metadata("design:type", Supplier)
    ], Project.prototype, "proofreader", void 0);
    tslib_1.__decorate([
        DateDef(),
        tslib_1.__metadata("design:type", Date)
    ], Project.prototype, "proofreading_deadline", void 0);
    tslib_1.__decorate([
        Relation(Status),
        tslib_1.__metadata("design:type", Status)
    ], Project.prototype, "status", void 0);
    tslib_1.__decorate([
        Relation(Division),
        tslib_1.__metadata("design:type", Division)
    ], Project.prototype, "division", void 0);
    tslib_1.__decorate([
        Relation(Task),
        tslib_1.__metadata("design:type", Array)
    ], Project.prototype, "tasks", void 0);
    return Project;
}());
export { Project };
