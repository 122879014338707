import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { Observable, Subject, BehaviorSubject, ObservableInput, throwError } from 'rxjs';
import { map, take, catchError, skipWhile } from 'rxjs/operators';
import { User } from '../../models/user';
import { StorageService } from '../storage.service';

/**
 * Represents a service to log the user in and resetting the password
 * @class AuthService
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Injectable({
	providedIn: 'root'
})
export class AuthService {

	/**
	 * The base url for the backend
	 */
	private backendBaseUrl: string;

	/**
	 * The authtenticated user, null otherwise
	 */
	private _authenticatedUser$: BehaviorSubject<User> = new BehaviorSubject(null);

	public authenticatedUser$: Observable<User> = this._authenticatedUser$.pipe();

	/**
	 * Constructs the service
	 * @param http
	 * @param appConfig
	 */
	constructor(
		private http: HttpClient,
		private appConfig: AppConfigService,
		private storage: StorageService) {
		this.backendBaseUrl = appConfig.get('backendBaseUrl');
	}

	/**
	 * Tries to log the user in with given username and password
	 * @param username the username
	 * @param password the password
	 * @returns An observable with the result of the request :
	 *   * If the login succeed, a 200 response is sent
	 *   * If the login failed, a 401 response is sent
	 */
	login(username: string, password: string): Observable<User> {
		const formData = {
			username: username,
			password: password
		};

		return this.http.post<any>(this.backendBaseUrl + '/login', formData)
			.pipe(map((data) => {
				this.storage.set('token', data.token);

				const user = new User();
				Object.assign(user, data.user);
				this._authenticatedUser$.next(user);
				return user;
			}));
	}

	/**
	 * Tries to log the user out
	 * @returns An observable with the result of the request.
	 */
	logout(): Observable<User> {
		this._authenticatedUser$.next(null);
		return this.http.get<User>(this.backendBaseUrl + '/logout')
			.pipe(map((data) => {
				this.storage.remove('token');

				const user = new User();
				Object.assign(user, data);
				return user;
			})).pipe(catchError(err => {
				return throwError(err);
			}));
	}

	/**
	 * Ask to the backend to send an activation code by mail
	 * @param email email the email to which the code should be sent
	 * @return An observable with the result of the request :
	 *   * If the reqsuest succeed, a 200 response
	 *   * If there is not account related to the email, a 404 response
	 *   * If there is another error, a 500 response
	 */
	sendResetPassword(email: string): Observable<HttpResponse<Object>> {
		return this.http.post<HttpResponse<Object>>(this.backendBaseUrl + '/reset-password', {
			email: email
		});
	}

	/**
	 * Ask to the server to validate the code and save the new password
	 * @param email the email of the account
	 * @param code the reset code
	 * @param password the new password
	 * @returns An observable with the response of the request
	 */
	validatePassword(email: string, code: string, password: string): Observable<HttpResponse<Object>> {
		return this.http.post<HttpResponse<Object>>(this.backendBaseUrl + '/reset-password-validate', {
			email: email,
			code: code,
			password: password
		});
	}

	/**
	 * Indicates if the user is authenticated or not
	 * @returns true if the user is authenticated, false otherwise.
	 */
	isAuthenticated(): boolean {
		return this._authenticatedUser$.getValue() != null;
	}

	/**
	 * Gets the authenticated user from the backend if any, null otherwise.
	 * @throws {HttpErrorResponse} if an error occured
	 * @returns the authenticated user
	 */
	getAuthenticatedUser(): Observable<Object> {
		return this.http.get<Object>(this.backendBaseUrl + '/authenticated')
		.pipe(take(1), map(data => {
			const user = new User();
			Object.assign(user, data);
			this._authenticatedUser$.next(user);
			return user;
		}));
		// return this._authenticatedUser$.asObservable();
	}

	getCurrentAuthenticatedUser(): User {
		return this._authenticatedUser$.getValue();
	}


}
