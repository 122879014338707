/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./is-dirty.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-ladda";
import * as i3 from "@ngneat/transloco";
import * as i4 from "./is-dirty.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_IsDirtyComponent = [i0.styles];
var RenderType_IsDirtyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IsDirtyComponent, data: {} });
export { RenderType_IsDirtyComponent as RenderType_IsDirtyComponent };
function View_IsDirtyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-default cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-warning continue"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 737280, null, 0, i2.LaddaDirective, [i1.ElementRef, [2, i2.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; var currVal_4 = _co.error; _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit("warnings.unsaved_form.title"); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit("warnings.unsaved_form.message"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit("warnings.unsaved_form.cancel_button"); _ck(_v, 8, 0, currVal_2); var currVal_5 = _v.context.$implicit("warnings.unsaved_form.continue_button"); _ck(_v, 11, 0, currVal_5); }); }
export function View_IsDirtyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IsDirtyComponent_1)), i1.ɵdid(1, 737280, null, 0, i3.TranslocoDirective, [i3.TranslocoService, [2, i1.TemplateRef], [2, i3.TRANSLOCO_SCOPE], [2, i3.TRANSLOCO_LANG], [2, i3.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IsDirtyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-is-dirty", [], null, null, null, View_IsDirtyComponent_0, RenderType_IsDirtyComponent)), i1.ɵdid(1, 49152, null, 0, i4.IsDirtyComponent, [i5.BsModalRef, i5.BsModalService], null, null)], null, null); }
var IsDirtyComponentNgFactory = i1.ɵccf("app-is-dirty", i4.IsDirtyComponent, View_IsDirtyComponent_Host_0, {}, {}, []);
export { IsDirtyComponentNgFactory as IsDirtyComponentNgFactory };
