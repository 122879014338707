import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

/**
 * Represents a service to manager a runtime configuration for the application.
 * @author Vincent Dieltiens <amazon@pm.creativewords.eu>
 */
@Injectable({
	providedIn: 'root'
})
export class AppConfigService {
	/**
	 * The configuration
	 */
	private config: any;

	/**
	 * Initialises the service
	 * @param http the http client
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Loads the configuration for an environment. The configuration should have the path `/assets/config.{env}.js`
	 * @param env the environment of the configuration to load. If no environment is given,
	 * it gets the environment name from the `environment.ts`
	 * @returns a promise that is resolved when the config is loaded
	 */
	load(env: string = null): Promise<void> {
		env = env ? env : environment.name;
		const jsonFile = '/assets/config.' + env + '.json';
		return new Promise<void>((resolve, reject) => {
			this.http.get(jsonFile).toPromise().then((response: any) => {
				this.config = <any>response;
				resolve();
			}).catch((response: any) => {
				reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
			});
		});
	}

	/**
	 * Gets all the configuration of a part
	 * @param path the path of the configuration to get
	 * @returns the configuratoin
	 */
	get(path = null): any {
		if (path !== null) {
			return this.config[path];
		}
		return this.config;
	}
}
