import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Input, Directive, Component } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-form',
	template: ''
})
export class FormComponent {
	@Input('errors') set _errors(errors: object) {
		this.setServerErrors(errors);
		this.errors = errors;
	}

	protected form: FormGroup;
	protected errors: any = 42;

	private subscriptions: Subscription[] = [];

	setServerErrors(errors: object) {
		if (!errors) {
			return;
		}

		this.setFieldErrors(errors, this.form);
	}

	private setFieldErrors(errors: object, formGroup: AbstractControl) {
		this.unsubscribleAllErrors();

		for (let [fieldName, fieldError] of Object.entries(errors)) {
			if (fieldError.errors && fieldError.errors.length > 0) {
				if (!formGroup.get(fieldName)) {
					throw new Error(`Can't set error on field ${fieldName}. Field does not exist`);
				}

				const errors = formGroup.get(fieldName).errors || {};
				errors.serverError = fieldError.errors[0];
				formGroup.get(fieldName).setErrors(errors);

				const subscription = formGroup.get(fieldName).valueChanges.subscribe(value => {
					console.log('field change : ', fieldName)
					this.removeServerError(formGroup.get(fieldName));
				});
				this.subscriptions.push(subscription);

				/*formGroup.get(fieldName).setErrors({
					serverError: fieldError.errors[0]
				});*/
			} else {
				// set serverError as null and if it's the only error on the field, remove it completely
				this.removeServerError(formGroup.get(fieldName));

				/*if (formGroup.get(fieldName) && formGroup.get(fieldName).errors && formGroup.get(fieldName).errors.serverError) {
					delete formGroup.get(fieldName).errors.serverError;
					if (JSON.stringify(formGroup.get(fieldName).errors) === JSON.stringify({})) {

						formGroup.get(fieldName).setErrors(null);
					}
				}*/
			}

			if (fieldError.children) {
				this.setFieldErrors(fieldError.children, formGroup.get(fieldName));
			}
		}
	}

	removeServerError(field: AbstractControl) {
		if (field && field.errors && field.errors.serverError) {
			delete field.errors.serverError;
			if (JSON.stringify(field.errors) == '{}') {
				field.setErrors(null);
			}
		}
	}

	unsubscribleAllErrors() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}

	ngOnDestroy() {
		this.unsubscribleAllErrors();
	}
}