/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./administration.component";
var styles_AdministrationComponent = [i0.styles];
var RenderType_AdministrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationComponent, data: {} });
export { RenderType_AdministrationComponent as RenderType_AdministrationComponent };
export function View_AdministrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "card-body p-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["class", "col-sm-4 col-md-4 index-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-users"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Utilisateurs "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 5, 0, "users"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_AdministrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-administration", [], null, null, null, View_AdministrationComponent_0, RenderType_AdministrationComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdministrationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdministrationComponentNgFactory = i1.ɵccf("app-administration", i4.AdministrationComponent, View_AdministrationComponent_Host_0, {}, {}, []);
export { AdministrationComponentNgFactory as AdministrationComponentNgFactory };
