import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet, ActivatedRoute } from '@angular/router';
import { trigger, style, query, group, animate, animation, animateChild, transition, useAnimation } from '@angular/animations';
import { fadeOutFadeInChildAnimation } from './animations/fade-out-fade-in-child.animation';

/**
 * Represents that App component which is the root component of the App
 * @author Vincent Dieltines <amazon@pm.creativewords.eu>
 */
@Component({
	// tslint:disable-next-line
	selector: 'body',
	template: '<ngx-loading-bar></ngx-loading-bar><div [@routeAnimations]="prepareRoute(outlet)"><router-outlet #outlet="outlet"></router-outlet></div>',
	animations: [
		trigger('routeAnimations', [
			transition('* => *', [
				useAnimation(fadeOutFadeInChildAnimation, {
					params: {
						time: '0.5s'
					}
				})
			])
		])
	]
})
export class AppComponent implements OnInit {
	/**
	 * Constructs the component
	 * @param router
	 */
	constructor(private router: Router) { }

	/**
	 * Component initialisation
	 */
	ngOnInit() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}

	/**
	 * Preparation for the animation
	 * @param outlet
	 * @returns the "state" name for a route or false
	 */
	prepareRoute(outlet: RouterOutlet): string | null | false {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}
}
