import { registerLocaleData } from '@angular/common';
import localeFrBE from '@angular/common/locales/fr-BE';
import localeFrBEExtra from '@angular/common/locales/extra/fr-BE';
import { TranslocoConfig, TranslocoService } from '@ngneat/transloco';
// App
import { environment } from '../environments/environment';
import { AppConfigService } from './services/app-config.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
registerLocaleData(localeFrBE, 'fr-BE', localeFrBEExtra);
/**
 * Loads the configuration using the AppConfig service
 * @param appConfig
 */
export function appInitialized(appConfig) {
    return function () { return appConfig.load(); };
}
/**
 * Preload the translator before the app is initialised so that the
 * translations are available at app start.
 * @param transloco the transloco service
 * @reutrns a function to run to load the `fr` language
 */
export function preloadTranslations(transloco) {
    return function () {
        return transloco.load('fr').toPromise();
    };
}
//frLocale.longDateFormat.LLTS = 'DD-MM-YYYY HH:mm:ss';
defineLocale('fr', frLocale);
export function getDatepickerConfig() {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'L LT'
    });
}
var ɵ0 = {
    availableLangs: ['fr'],
    prodMode: environment.production,
    defaultLang: 'fr',
    fallbackLang: 'fr'
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
