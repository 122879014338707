import { FormGroup, AbstractControl } from '@angular/forms';
var FormComponent = /** @class */ (function () {
    function FormComponent() {
        this.errors = 42;
        this.subscriptions = [];
    }
    Object.defineProperty(FormComponent.prototype, "_errors", {
        set: function (errors) {
            this.setServerErrors(errors);
            this.errors = errors;
        },
        enumerable: true,
        configurable: true
    });
    FormComponent.prototype.setServerErrors = function (errors) {
        if (!errors) {
            return;
        }
        this.setFieldErrors(errors, this.form);
    };
    FormComponent.prototype.setFieldErrors = function (errors, formGroup) {
        var _this = this;
        this.unsubscribleAllErrors();
        var _loop_1 = function (fieldName, fieldError) {
            if (fieldError.errors && fieldError.errors.length > 0) {
                if (!formGroup.get(fieldName)) {
                    throw new Error("Can't set error on field " + fieldName + ". Field does not exist");
                }
                var errors_1 = formGroup.get(fieldName).errors || {};
                errors_1.serverError = fieldError.errors[0];
                formGroup.get(fieldName).setErrors(errors_1);
                var subscription = formGroup.get(fieldName).valueChanges.subscribe(function (value) {
                    console.log('field change : ', fieldName);
                    _this.removeServerError(formGroup.get(fieldName));
                });
                this_1.subscriptions.push(subscription);
                /*formGroup.get(fieldName).setErrors({
                    serverError: fieldError.errors[0]
                });*/
            }
            else {
                // set serverError as null and if it's the only error on the field, remove it completely
                this_1.removeServerError(formGroup.get(fieldName));
                /*if (formGroup.get(fieldName) && formGroup.get(fieldName).errors && formGroup.get(fieldName).errors.serverError) {
                    delete formGroup.get(fieldName).errors.serverError;
                    if (JSON.stringify(formGroup.get(fieldName).errors) === JSON.stringify({})) {

                        formGroup.get(fieldName).setErrors(null);
                    }
                }*/
            }
            if (fieldError.children) {
                this_1.setFieldErrors(fieldError.children, formGroup.get(fieldName));
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.entries(errors); _i < _a.length; _i++) {
            var _b = _a[_i], fieldName = _b[0], fieldError = _b[1];
            _loop_1(fieldName, fieldError);
        }
    };
    FormComponent.prototype.removeServerError = function (field) {
        if (field && field.errors && field.errors.serverError) {
            delete field.errors.serverError;
            if (JSON.stringify(field.errors) == '{}') {
                field.setErrors(null);
            }
        }
    };
    FormComponent.prototype.unsubscribleAllErrors = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    FormComponent.prototype.ngOnDestroy = function () {
        this.unsubscribleAllErrors();
    };
    return FormComponent;
}());
export { FormComponent };
